/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import SideBarUi from "./sidebar";
import NavBarUi from "./navbar";

const Wrapper = ({ appLink, ...props }) => {
	return (
		<React.Fragment>
			<div className="container-scroller">
				<NavBarUi appLink={appLink} />

				<div className="container-fluid page-body-wrapper">
					<SideBarUi appLink={appLink} />

					<div className="main-panel">
						<div className="content-wrapper">{props.children}</div>

						<footer className="footer">
							<div className="container-fluid clearfix">
								<span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
									Copyright © {new Date().getFullYear()}
									<span className="ml-1">BitAfrika</span>. All rights reserved.
								</span>
							</div>
						</footer>
						{/* partial */}
					</div>
					{/* main-panel ends */}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Wrapper;
