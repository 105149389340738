import React, { useState } from "react";
import FormLoading from "../loader/form_loading";
import { SendPostRequest } from "../util/request";
import { useStoreActions } from "easy-peasy";
import { passwordValidator } from "../util/misc";
import AppLink from "../util/app_links";

const SignInUI = () => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const authenticateUser = useStoreActions((actions) => actions.authenticateUser);

	const signInUser = (e) => {
		e.preventDefault();

		if (username.length < 3) {
			setError("Your username is invalid");
			return;
		}

		if (!passwordValidator(password)) {
			setError("Password should be between 6 to 20 characters and contain at least one numeric digit, one uppercase and one lowercase letter");
			return;
		}

		setLoaderShow(true);

		SendPostRequest(AppLink.signInEndpoint, {
			password: password,
			username: username,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					authenticateUser(response.data.account);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="container-scroller">
				<div className="container-fluid page-body-wrapper full-page-wrapper">
					<div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
						<div className="row w-100 mx-auto">
							<div className="col-lg-4 mx-auto">
								{error !== null && (
									<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
										{error}
										<button type="button" className="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								)}

								<div className="auto-form-wrapper">
									<h3 className="w-100 text-center text-primary mb-3">BitAfrika</h3>

									{!loaderShow ? (
										<React.Fragment>
											<form onSubmit={signInUser}>
												<div className="form-group">
													<label className="label">Username</label>
													<input type="text" className="form-control" onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Username" />
												</div>
												<div className="form-group">
													<label className="label">Password</label>
													<input type="password" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
												</div>
												<div className="form-group">
													<button className="btn btn-primary submit-btn btn-block">Login</button>
												</div>
											</form>
										</React.Fragment>
									) : (
										<FormLoading />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SignInUI;
