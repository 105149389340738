import React from 'react';
import Wrapper from "../misc/wrapper";
import SiteConfiguration from "./body";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const AdminConfig = () => {
    return (
        <React.Fragment>

            <Helmet>
                <title>Admin Center | BitAfrika</title>
            </Helmet>

            <Wrapper appLink={AppLinks}>
                <SiteConfiguration appLink={AppLinks} />
            </Wrapper>
        </React.Fragment>
    )
}

export default AdminConfig;