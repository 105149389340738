import React from 'react';
import Wrapper from "../misc/wrapper";
import Addressses from "./body";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const AddressList = () => {
    return (
        <React.Fragment>

            <Helmet>
                <title>Addresses | BitAfrika</title>
            </Helmet>

            <Wrapper appLink={AppLinks}>
                <Addressses appLink={AppLinks} />
            </Wrapper>
        </React.Fragment>
    )
}

export default AddressList;