/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import FormLoading from '../../loader/form_loading';
import { useStoreState } from 'easy-peasy';
import { SendPostRequest } from '../../util/request';
import { validate_amount } from '../../util/misc'

const UserBalance = ({ appLink }) => {

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const [loaderShow, setLoaderShow] = useState(false);

    const account = useStoreState((state) => state.account);

    const [users, setUsers] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState({
        id: null,
        username: "",
        crypto: "0",
        cryptoSymbol: "",
        indexTbl: null
    })

    useEffect(() => {

        setLoaderShow(true);

        SendPostRequest(
            appLink.userBalanceEndpoint,
            {
                token: account.token
            }
        ).then(function (response) {

            setLoaderShow(false);

            if (response.data.success) {

                let users = []
                let usdPrice = response.data.usd

                response.data.users.map((user) =>
                    users.push({
                        ...user,
                        usdBalance: validate_amount(user.balance * usdPrice, 3)
                    })
                )

                setUsers(users);
            } else {
                setError(response.data.message);
            }

        }).catch((error) => {

            setLoaderShow(false);

            setError("Unable to connect to server. Check Internet")

        });

    }, []);

    const fundAddress = (e) => {
        e.preventDefault();

        SendPostRequest(
            appLink.fundBalanceEndpoint,
            {
                token: account.token,
                ...selectedAccount
            }
        ).then(function (response) {

            if (response.data.success) {
                setSelectedAccount({
                    id: null,
                    username: "",
                    crypto: "0",
                    cryptoSymbol: "",
                    indexTbl: null
                })
                setSuccess(response.data.message)
            } else {
                setError(response.data.message);
            }

        }).catch((error) => {

            setLoaderShow(false);

            setError("Unable to connect to server. Check Internet")

        });

    }

    return (
        <React.Fragment>

            {error !== null && (
                <div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
                    {error}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}

            {success !== null && (
                <div className="alert alert-success alert-fill-success alert-dismissible fade show" role="alert">
                    {success}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )}

            {
                !loaderShow ? (
                    <React.Fragment>

                        {
                            selectedAccount.id !== null &&
                            <div className="card grid-margin">
                                <div className="card-body">
                                    <h4 className="card-title">User: {selectedAccount.username}</h4>

                                    <form className="forms-sample" onSubmit={fundAddress}>
                                        <div className="form-group">
                                            <label>Top up amount</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-primary border-primary">
                                                    <span className="input-group-text bg-transparent text-white">{selectedAccount.cryptoSymbol}</span>
                                                </div>
                                                <input type="text" className="form-control" value={selectedAccount.crypto} onChange={(e) => {
                                                    setSelectedAccount(
                                                        {
                                                            ...selectedAccount,
                                                            crypto: e.target.value
                                                        }
                                                    )
                                                }} />

                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary mr-2">Fund Account</button>
                                        <button className="btn btn-light" onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedAccount({
                                                id: null,
                                                username: "",
                                                crypto: "0",
                                                cryptoSymbol: "",
                                                indexTbl: null
                                            })
                                        }}>Cancel</button>
                                    </form>
                                </div>
                            </div>

                        }


                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Users and their balance</h4>
                                <div className="row">
                                    <div className="col-12 table-responsive">
                                        <table id="order-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Balance</th>
                                                    <th>USD</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users !== null && users.map((user, index) =>

                                                    <tr key={index}>
                                                        <td>{user.username}</td>
                                                        <td>{user.balance} {user.crypto}</td>
                                                        <td>${user.usdBalance}</td>
                                                        <td>
                                                            <button className="btn btn-primary mr-1" onClick={(e) =>

                                                                setSelectedAccount(
                                                                    {
                                                                        id: user.id,
                                                                        username: user.username,
                                                                        crypto: user.balance,
                                                                        cryptoSymbol: user.crypto,
                                                                        indexTbl: index
                                                                    }
                                                                )
                                                            }>Select</button>
                                                        </td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                ) : (
                        <FormLoading />
                    )
            }

        </React.Fragment>
    )
}

export default UserBalance;