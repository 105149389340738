import React from "react";
import Wrapper from "../misc/wrapper";
import SiteConfiguration from "./emailBody";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const EmailBroadcast = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Email Broadcast | BitAfrika</title>
			</Helmet>

			<Wrapper appLink={AppLinks}>
				<SiteConfiguration appLink={AppLinks} />
			</Wrapper>
		</React.Fragment>
	);
};

export default EmailBroadcast;
