import { createStore, action, persist } from "easy-peasy";

const store = createStore(
	persist(
		{
			loggedIn: false,
			account: null,
			authenticateUser: action((state, payload) => {
				state.account = payload;
				state.loggedIn = true;
			}),
			updateUser: action((state, payload) => {
				state.account = {
					...state.account,
					payload,
				};
			}),
			logOffUser: action((state) => {
				state.account = null;
				state.loggedIn = false;
			}),
		},
		{
			storage: "localStorage",
		}
	)
);

export default store;
