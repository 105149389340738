/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const CountryListUI = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const [deleteCountry, setCountryDelete] = useState(false);
	const [addCountry, setCountryAdd] = useState(false);

	const [newCountry, setNewCountryInfo] = useState({
		name: "",
		shortName: "",
		currencyName: "",
		currencyCode: "",
		phoneCode: "",
		momo: false,
		bank: false,
		chipper: false,
		currencyRate: "0",
		sellRate: "0",
		usdtBuyRate: "0",
		usdtSellRate: "0",
		evmBuyRate: "0",
		evmSellRate: "0",
	});

	const [selectedCountry, setSelectedCountry] = useState({
		id: null,
		name: "",
		shortName: "",
		currencyName: "",
		currencyCode: "",
		phoneCode: "",
		momo: false,
		bank: false,
		chipper: false,
		currencyRate: "0",
		sellRate: "0",
		usdtBuyRate: "0",
		usdtSellRate: "0",
		evmBuyRate: "0",
		evmSellRate: "0",
	});

	const account = useStoreState((state) => state.account);

	const [countries, setData] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.getCountriesEndpoint, {
			token: account.token,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const createCompany = (e) => {
		e.preventDefault();

		SendPostRequest(appLink.createCountryEndpoint, {
			token: account.token,
			...newCountry,
		})
			.then(function (response) {
				if (response.data.success) {
					setData([...countries, response.data.country]);

					setNewCountryInfo({
						id: null,
						name: "",
						shortName: "",
						currencyName: "",
						currencyCode: "",
						phoneCode: "",
						momo: false,
						bank: false,
						chipper: false,
						currencyRate: "0",
						sellRate: "0",
						usdtBuyRate: "0",
						usdtSellRate: "0",
						evmBuyRate: "0",
						evmSellRate: "0",
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const updateCompany = (e) => {
		e.preventDefault();

		SendPostRequest(appLink.updateCountryEndpoint, {
			token: account.token,
			...selectedCountry,
		})
			.then(function (response) {
				setError(null);

				if (response.data.success) {
					window.location.reload();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const deleteCountryRequest = (e) => {
		e.preventDefault();

		SendPostRequest(appLink.deleteCountryEndpoint, {
			token: account.token,
			id: selectedCountry.id,
		})
			.then(function (response) {
				if (response.data.success) {
					setSelectedCountry({
						id: null,
						name: "",
						shortName: "",
						currencyName: "",
						currencyCode: "",
						phoneCode: "",
						momo: false,
						bank: false,
						chipper: false,
						currencyRate: "",
						sellRate: "",
						usdtBuyRate: "",
						usdtSellRate: "",
						evmBuyRate: "",
						evmSellRate: "",
					});

					setNewCountryInfo({
						name: "",
						shortName: "",
						currencyName: "",
						currencyCode: "",
						phoneCode: "",
						momo: false,
						bank: false,
						chipper: false,
						currencyRate: "",
						sellRate: "",
						usdtBuyRate: "",
						usdtSellRate: "",
						evmBuyRate: "",
						evmSellRate: "",
					});
					setCountryAdd(false);
					setCountryDelete(false);

					const c = countries.filter((country) => country.id !== selectedCountry.id);
					setData(c);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="row col-12">
				<div className="w-100">
					{error !== null && (
						<div className="col-12 alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
							{error}
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					)}

					{success !== null && (
						<div className="col-12 alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
							{success}
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					)}

					{deleteCountry && (
						<div className="col-12 alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
							Are you sure you want delete {selectedCountry.name}. Users would not be able to operate their account in any capacity
							<button type="button" className="ml-3 btn btn-xs btn-warning" onClick={deleteCountryRequest}>
								<span className="menu-icon icon-trash text-dark" style={{ fontFamily: "Work Sans" }}>
									{" "}
									<b>Yes!! Delete Country</b>
								</span>
							</button>
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					)}

					{!addCountry && !deleteCountry && (
						<button
							className="btn btn-danger mb-3"
							onClick={(e) => {
								e.preventDefault();
								setCountryAdd(true);
								setSelectedCountry({
									...selectedCountry,
									id: null,
								});
							}}
						>
							Add New Country
						</button>
					)}

					{addCountry && selectedCountry.id === null && (
						<div className="w-100">
							<div className="card grid-margin">
								<div className="card-body">
									<h4 className="card-title">Add New Country</h4>

									<form className="forms-sample" onSubmit={createCompany}>
										<div className="form-group row">
											<div className="col-sm-12 col-md-4 col-lg-4 mt-2">
												<label>Name ( eg. Ghana )</label>
												<input
													type="text"
													className="form-control"
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															name: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col-sm-12 col-md-4 col-lg-4 mt-2">
												<label>Code ( eg. NG )</label>
												<input
													type="text"
													className="form-control"
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															shortName: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col-sm-12 col-md-4 col-lg-4 mt-2">
												<label>Currency Code ( eg. USD )</label>
												<input
													type="text"
													className="form-control"
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															currencyCode: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="form-group row mt-4">
											<div className="col">
												<label className="w-100">Mobile Money</label>
												<input
													type="checkbox"
													checked={newCountry.momo}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															momo: e.target.checked,
														});
													}}
												/>{" "}
												{newCountry.momo ? "Allowed" : "Disabled"}
											</div>
											<div className="col">
												<label className="w-100">Bank Transfer</label>
												<input
													type="checkbox"
													checked={newCountry.bank}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															bank: e.target.checked,
														});
													}}
												/>{" "}
												{newCountry.bank ? "Allowed" : "Disabled"}
											</div>
											<div className="col">
												<label className="w-100">ChipperCash</label>
												<input
													type="checkbox"
													checked={newCountry.chipper}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															chipper: e.target.checked,
														});
													}}
												/>{" "}
												{newCountry.chipper ? "Allowed" : "Disabled"}
											</div>
										</div>

										<div className="form-group row mt-2">
											<div className="col">
												<label>Currency Name ( eg. US Dollar )</label>
												<input
													type="text"
													className="form-control"
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															currencyName: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col">
												<label>Phone Code ( eg. 234 )</label>
												<input
													type="text"
													className="form-control"
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setNewCountryInfo({
															...newCountry,
															phoneCode: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="form-group row">
											<div className="col">
												<label>Buy Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setNewCountryInfo({
																...newCountry,
																currencyRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>Sell/Withdrawal Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setNewCountryInfo({
																...newCountry,
																sellRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>USDT BUY Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setNewCountryInfo({
																...newCountry,
																usdtBuyRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>USDT SELL Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setNewCountryInfo({
																...newCountry,
																usdtSellRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>

											<div className="col">
												<label>EVM BUY Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setNewCountryInfo({
																...newCountry,
																evmBuyRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>

											<div className="col">
												<label>EVM SELL Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setNewCountryInfo({
																...newCountry,
																evmSellRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
										</div>
										<button type="submit" className="btn btn-primary mr-2">
											Create
										</button>
										<button
											className="btn btn-light"
											onClick={(e) => {
												e.preventDefault();
												setSelectedCountry({
													id: null,
													name: "",
													shortName: "",
													currencyName: "",
													currencyCode: "",
													phoneCode: "",
													momo: false,
													bank: false,
													chipper: false,
													currencyRate: "0",
													sellRate: "0",
													usdtBuyRate: "0",
													usdtSellRate: "0",
													evmBuyRate: "0",
													evmSellRate: "0",
												});
												setCountryAdd(false);
											}}
										>
											Cancel
										</button>
									</form>
								</div>
							</div>
						</div>
					)}

					{selectedCountry.id !== null && (
						<div className="w-100">
							<div className="card grid-margin">
								<div className="card-body">
									<h4 className="card-title">Edit Country</h4>

									<form className="forms-sample" onSubmit={updateCompany}>
										<div className="form-group row">
											<div className="col-sm-12 col-md-4 col-lg-4 mt-2">
												<label>Name ( eg. Ghana )</label>
												<input
													type="text"
													className="form-control"
													value={selectedCountry.name}
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															name: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col-sm-12 col-md-4 col-lg-4 mt-2">
												<label>Code ( eg. NG )</label>
												<input
													type="text"
													className="form-control"
													value={selectedCountry.shortName}
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															shortName: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col-sm-12 col-md-4 col-lg-4 mt-2">
												<label>Currency Code ( eg. USD )</label>
												<input
													type="text"
													className="form-control"
													value={selectedCountry.currencyCode}
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															currencyCode: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="form-group row mt-4">
											<div className="col">
												<label className="w-100">Mobile Money</label>
												<input
													type="checkbox"
													checked={selectedCountry.momo}
													onChange={(e) => {
														setSelectedCountry({
															...selectedCountry,
															momo: e.target.checked,
														});
													}}
												/>{" "}
												{selectedCountry.momo ? "Allowed" : "Disabled"}
											</div>
											<div className="col">
												<label className="w-100">Bank Transfer</label>
												<input
													type="checkbox"
													checked={selectedCountry.bank}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															bank: e.target.checked,
														});
													}}
												/>{" "}
												{selectedCountry.bank ? "Allowed" : "Disabled"}
											</div>
											<div className="col">
												<label className="w-100">ChipperCash</label>
												<input
													type="checkbox"
													checked={selectedCountry.chipper}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															chipper: e.target.checked,
														});
													}}
												/>{" "}
												{selectedCountry.chipper ? "Allowed" : "Disabled"}
											</div>
										</div>

										<div className="form-group row mt-2">
											<div className="col">
												<label>Currency Name ( eg. US Dollar )</label>
												<input
													type="text"
													className="form-control"
													value={selectedCountry.currencyName}
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															currencyName: e.target.value,
														});
													}}
												/>
											</div>
											<div className="col">
												<label>Phone Code ( eg. 234 )</label>
												<input
													type="text"
													className="form-control"
													value={selectedCountry.phoneCode}
													required={true}
													onChange={(e) => {
														e.preventDefault();
														setSelectedCountry({
															...selectedCountry,
															phoneCode: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="form-group row">
											<div className="col">
												<label>Buy Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														value={selectedCountry.currencyRate}
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setSelectedCountry({
																...selectedCountry,
																currencyRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>Sell/Withdrawal Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														value={selectedCountry.sellRate}
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setSelectedCountry({
																...selectedCountry,
																sellRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>USDT BUY Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														value={selectedCountry.usdtBuyRate}
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setSelectedCountry({
																...selectedCountry,
																usdtBuyRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>USDT SELL Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														value={selectedCountry.usdtSellRate}
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setSelectedCountry({
																...selectedCountry,
																usdtSellRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>EVM BUY Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														value={selectedCountry.evmBuyRate}
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setSelectedCountry({
																...selectedCountry,
																evmBuyRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
											<div className="col">
												<label>EVM SELL Rate</label>
												<div className="input-group">
													<div className="input-group-prepend bg-primary border-primary">
														<span className="input-group-text bg-transparent text-white">$</span>
													</div>
													<input
														type="text"
														className="form-control"
														value={selectedCountry.evmSellRate}
														required={true}
														onChange={(e) => {
															e.preventDefault();
															setSelectedCountry({
																...selectedCountry,
																evmSellRate: e.target.value,
															});
														}}
													/>
												</div>
											</div>
										</div>
										<button type="submit" className="btn btn-primary mr-2">
											Update Country Information
										</button>
										<button
											className="btn btn-danger mr-2"
											onClick={(e) => {
												e.preventDefault();
												setCountryDelete(true);
											}}
										>
											Delete
										</button>
										<button
											className="btn btn-info"
											onClick={(e) => {
												e.preventDefault();
												setCountryAdd(false);
												setSelectedCountry({
													...newCountry,
													id: null,
												});
											}}
										>
											Close
										</button>
									</form>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="card">
				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">The list of countries supported the platform</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Name</th>
												<th>Phone Code</th>
												<th>Payment</th>
												<th>Buy Rate</th>
												<th>Sell Rate</th>
												<th>USDT Buy Rate</th>
												<th>USDT Sell Rate</th>
												<th>EVM Buy Rate</th>
												<th>EVM Sell Rate</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{countries !== null &&
												countries.map((data, index) => (
													<tr key={index}>
														<td>
															{data.name} ( {data.shortName} - {data.currencyCode} )
														</td>
														<td>{data.phoneCode}</td>
														<td>
															Bank: {data.bank ? <span className="text-success">Enabled</span> : <span className="text-danger">Disabled</span>}
															<br />
															Momo: {data.momo ? <span className="text-success">Enabled</span> : <span className="text-danger">Disabled</span>}
															<br />
															Chipper: {data.chipper ? <span className="text-success">Enabled</span> : <span className="text-danger">Disabled</span>}
														</td>
														<td>
															{data.currencyRate} {data.currencyCode}
														</td>
														<td>
															{data.sellRate} {data.currencyCode}
														</td>
														<td>
															{data.usdtBuyRate} {data.currencyCode}
														</td>
														<td>
															{data.usdtSellRate} {data.currencyCode}
														</td>
														<td>
															{data.evmBuyRate} {data.currencyCode}
														</td>
														<td>
															{data.evmSellRate} {data.currencyCode}
														</td>
														<td>
															<button
																className="btn btn-primary mr-1"
																onClick={(e) => {
																	e.preventDefault();
																	setCountryAdd(false);
																	setSelectedCountry({
																		...data,
																		id: data.id,
																	});
																}}
															>
																Select
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default CountryListUI;
