/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import base64 from "base-64";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";
import { validate_amount } from "../../util/misc";

const AccTopUps = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);
	const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);

	const [topUpData, setTopUpData] = useState(null);
	const [topupStatement, setTopUpStatement] = useState("");

	const searchDate = (date) => {
		setLoaderShow(true);

		SendPostRequest(appLink.userAccountTopUpsEndpoint, {
			token: account.token,
			status: "success",
			show: date,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setTopUpData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	useEffect(() => {
		searchDate(currentDate);
	}, []);

	useEffect(() => {
		if (topUpData !== null) {
			let totalBoughtGHS = 0;
			let totalBoughtUSD = 0;
			// const totalBoughtCrypto = 0;

			for (let index = 0; index < topUpData.length; index++) {
				const tx = topUpData[index];

				totalBoughtGHS += Number(tx.amount);
				totalBoughtUSD += Number(tx.usd);
			}

			setTopUpStatement(`Total Bought in GHS: ${validate_amount(totalBoughtGHS, 2)} | Total Bought in USD: ${validate_amount(totalBoughtUSD, 2)}`);
		}
	}, [topUpData]);

	const viewMethodInfo = (e, methodName, methodId) => {
		e.preventDefault();

		SendPostRequest(appLink.viewPaymentMethodEndpoint, {
			token: account.token,
			methodName: methodName,
			methodId: methodId,
		})
			.then(function (response) {
				if (response.data.success) {
					let payInfo = response.data.data;
					let message =
						methodName === "momo"
							? `Account: ${base64.decode(payInfo.name)}\n\nCountry: ${payInfo.countryCode}\nNetwork: ${payInfo.networkCode}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: methodName === "bank"
							? `Account Name: ${payInfo.accountName.length !== 0 ? base64.decode(payInfo.accountName) : "None"}\nAccount Number: ${payInfo.accountNumber.length !== 0 ? base64.decode(payInfo.accountNumber) : "None"}\n\nCountry: ${
									payInfo.countryCode
							  }\n\nBank Name: ${base64.decode(payInfo.bankName)}\nSwift / BiC: ${payInfo.swiftBic.length !== 0 ? base64.decode(payInfo.swiftBic) : "None"}\nRouting Number: ${
									payInfo.routingNumber.length !== 0 ? base64.decode(payInfo.routingNumber) : "None"
							  }\n\nPayment Method Status: ${payInfo.active ? "Active" : "Disabled"}\nRegistered on ${payInfo.createdOn}`
							: methodName === "chipper"
							? `Account: ${base64.decode(payInfo.chipperName)}\n\nCountry: ${payInfo.countryCode}\nChipper Username: ${base64.decode(payInfo.Username)}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: "Unknown Payment Method";

					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">
								Momo Top Ups for: <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} />{" "}
								<button type="button" className="btn btn-primary" onClick={(e) => searchDate(currentDate)}>
									Search this Date
								</button>
								<br />
								<br />
								The list of successful account top ups performed on the platform - {topUpData !== null && topUpData.length} top ups
							</h4>
							{topupStatement !== "" && <h3 className="mt-3 mb-3">{topupStatement}</h3>}

							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>TxId</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											{topUpData !== null &&
												topUpData.map((user, index) => (
													<tr key={index}>
														<td>
															<a href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																{user.username}
															</a>
														</td>
														<td>
															{user.uniqId}
															<br />
															<small>{user.date}</small>
															<br />
															<small>{user.methodId}</small>
														</td>
														<td>
															{user.amount} {user.currency} ( {user.crypto} {user.cryptoSymbol} )<br />
															<small>${user.usd}</small> -{" "}
															<small>
																Bought @ {user.rate} (${user.cryptoPrice})
															</small>
														</td>
														{/* <td>
															<button className="btn btn-primary mr-1" onClick={(e) => viewMethodInfo(e, user.methodName, user.methodId)}>
																View
															</button>
														</td> */}
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default AccTopUps;
