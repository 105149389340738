import React from "react";
import Wrapper from "../misc/wrapper";
import SearchUserBody from "./searchUserBody";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const SearchUser = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Search User | BitAfrika</title>
			</Helmet>

			<Wrapper appLink={AppLinks}>
				<SearchUserBody appLink={AppLinks} />
			</Wrapper>
		</React.Fragment>
	);
};

export default SearchUser;
