/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import base64 from "base-64";
import FormLoading from "../../loader/form_loading";
import Lightbox from "react-image-lightbox";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const KYCList = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);
	const [photos, setPhotos] = useState(null);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [users, setUsers] = useState(null);
	const [userVerInfo, setUserVerInfo] = useState(null);
	const [jsonIDInfo, setJSONIDInfo] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.kycEndpoint, {
			token: account.token,
			status: "pending",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setUsers(response.data.kyc);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const updataKYCStatus = (e, username, status, reason) => {
		e.preventDefault();

		SendPostRequest(appLink.updateUserEndpoint, {
			token: account.token,
			username: username,
			status: status,
			reason: reason,
		})
			.then(function (response) {
				if (response.data.success) {
					const activeUsers = users.filter((user) => user.username !== username);
					setUsers(activeUsers);

					window.swal({
						text: `The kyc documents for (${username}) has been ${status === "success" ? "approved" : "rejected"}.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const viewPhotos = (e, data) => {
		e.preventDefault();
		setPhotos(data);
	};

	const closePhotos = () => {
		setPhotos(null);
		setPhotoIndex(0);
	};

	const processUserKYCModal = (user) => {
		setUserVerInfo({ ...user, otherDocumentId: undefined });
		setJSONIDInfo(JSON.parse(base64.decode(user.otherDocumentId)));
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						{photos !== null && (
							<Lightbox
								mainSrc={photos[photoIndex]}
								nextSrc={photos[(photoIndex + 1) % photos.length]}
								prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
								onCloseRequest={() => closePhotos()}
								onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
								onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
							/>
						)}
						<div className="card-body">
							<h4 className="card-title">The list of all pending kyc waiting for approval</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>Photo</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{users !== null &&
												users.map((user, index) => (
													<tr key={index}>
														<td>
															<a href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																{user.username}
															</a>
														</td>
														<td>
															<a
																onClick={(e) =>
																	user.photoId.length > 5
																		? viewPhotos(e, [base64.decode(user.photoId), base64.decode(user.SelfieId) /*, base64.decode(user.CardId)*/])
																		: viewPhotos(e, [base64.decode(user.SelfieId), base64.decode(user.CardId)])
																}
																className="btn btn-warning mr-1"
																rel="noreferrer noopener"
																href={atob(user.photoId)}
																target="_blank"
															>
																View Photos
															</a>

															{user.otherDocumentId.length > 5 && (
																<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" onClick={(e) => processUserKYCModal(user)}>
																	View Info
																</button>
															)}
														</td>
														<td>
															<button
																className="btn btn-success mr-2"
																onClick={(e) => {
																	updataKYCStatus(e, user.username, "success", "");
																}}
															>
																Approve
															</button>

															<button
																className="btn btn-outline-danger"
																onClick={(e) => {
																	let reason = prompt("Reason for disapproving user kyc. This is shown to the user to help them do better when re-uploading their kyc");

																	if (reason === null || reason === undefined) {
																		window.swal({
																			text: "You need to provide a reason for suspending a valid and approved KYC document",
																			button: {
																				text: "OK",
																				value: true,
																				visible: true,
																				className: "btn btn-primary",
																			},
																		});
																		return;
																	}

																	updataKYCStatus(e, user.username, "error", reason);
																}}
															>
																Reject
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}

				{userVerInfo !== null && (
					<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-lg" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLabel">
										<a href={appLink.user.replace(":id", userVerInfo.username)} target="_blank" rel="noopener noreferrer">
											{userVerInfo.username}
										</a>
									</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">×</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="container-fluid">
										<div className="row">
											<div className="col-3">
												<img src={`data:image/png;base64,${jsonIDInfo.picture}`} width="150" height="150" alt="User" />
											</div>

											<div className="col-9">
												<div className="row">
													<div className="col">
														<div className="row">
															{jsonIDInfo.id && <div className="w-100">ID: {jsonIDInfo.id}</div>}
															{jsonIDInfo.first_name && <div className="w-100">First Name: {jsonIDInfo.first_name}</div>}
															{jsonIDInfo.full_name && <div className="w-100">Full Name: {jsonIDInfo.full_name}</div>}
															{jsonIDInfo.certificate_date && <div className="w-100">Certificate Date: {jsonIDInfo.certificate_date}</div>}
															{jsonIDInfo.processing_center && <div className="w-100">Processing Center: {jsonIDInfo.processing_center}</div>}
															{jsonIDInfo.pin && <div className="w-100">PIN: {jsonIDInfo.pin}</div>}

															{jsonIDInfo.last_name && <div className="w-100">Last Name: {jsonIDInfo.last_name}</div>}
															{jsonIDInfo.middle_name && <div className="w-100">Other Name: {jsonIDInfo.middle_name}</div>}
															{jsonIDInfo.date_of_birth && <div className="w-100">DOB: {jsonIDInfo.date_of_birth}</div>}
															{jsonIDInfo.gender && <div className="w-100">Gender: {jsonIDInfo.gender}</div>}

															{jsonIDInfo.polling_station && <div className="w-100">Polling Station: {jsonIDInfo.polling_station}</div>}
															{jsonIDInfo.registration_date && <div className="w-100">Registration: {jsonIDInfo.registration_date}</div>}
														</div>
													</div>

													<div className="col">
														<div className="row">
															{jsonIDInfo.issue_date && <div className="w-100">Issue Date: {jsonIDInfo.issue_date}</div>}
															{jsonIDInfo.expiry_date && <div className="w-100">Expiry Date: {jsonIDInfo.expiry_date}</div>}

															{jsonIDInfo.place_of_birth && <div className="w-100">Place of Birth: {jsonIDInfo.place_of_birth}</div>}
															{jsonIDInfo.place_of_issue && <div className="w-100">Place of Issue: {jsonIDInfo.place_of_issue}</div>}
															{jsonIDInfo.documentType && <div className="w-100">Document Type: {jsonIDInfo.documentType}</div>}

															{jsonIDInfo.dob && <div className="w-100">DOB: {new Date(jsonIDInfo.dob).toDateString()}</div>}
															{jsonIDInfo.cardNo && <div className="w-100">Card No: {jsonIDInfo.cardNo}</div>}
															{jsonIDInfo.homeAddress && <div className="w-100">GhanaPostGPS: {jsonIDInfo.homeAddress}</div>}
															{jsonIDInfo.cardExpiry && <div className="w-100">Card Expiry: {new Date(jsonIDInfo.cardExpiry).toDateString()}</div>}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-secondary" data-dismiss="modal">
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default KYCList;
