/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const SearchUserBody = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [userSearch, setUserSearch] = useState("");
	const [searchData, setSearchData] = useState(null);

	const initiateSearch = () => {
		setLoaderShow(true);

		SendPostRequest(appLink.searchUserEndpoint, {
			token: account.token,
			user: userSearch,
			state: "active",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setSearchData(response.data.users);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body mb-3">
							<h4 className="card-title">Search for a user</h4>

							<div className="form-group mb-3">
								<input type="text" className="form-control" value={userSearch} placeholder="Search for user by phone, username or email address" onChange={(e) => setUserSearch(e.target.value)} />
							</div>

							<button
								className="btn btn-primary mr-3"
								onClick={(e) => {
									e.preventDefault();
									initiateSearch();
								}}
							>
								Search
							</button>

							{searchData !== null && (
								<React.Fragment>
									<div className="col-12 table-responsive">
										<table id="order-listing" className="table dataTable no-footer" role="grid" aria-describedby="order-listing_info">
											<thead>
												<tr>
													<th>Username</th>
													<th>Contact</th>
													<th>Country</th>
													<th>Actions</th>
												</tr>
											</thead>
											<tbody>
												{searchData !== null &&
													searchData.map((user, index) => (
														<tr key={index}>
															<td>
																<a href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																	{user.username}
																</a>
															</td>
															<td>
																{user.phone} {user.email.length > 0 && `( ${user.email} )`}
															</td>
															<td>{user.countryCode}</td>
															<td>
																<a className="btn btn-primary mr-1" href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																	View
																</a>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</React.Fragment>
							)}
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default SearchUserBody;
