/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";

const NavBarUi = ({ appLink }) => {
	const account = useStoreState((state) => state.account);
	const logOffUser = useStoreActions((actions) => actions.logOffUser);

	return (
		<React.Fragment>
			<nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
				<div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
					<a className="navbar-brand brand-logo" href={appLink.appRoot}>
						<img src="https://bitafrika.com/image/svg/logo-w.svg" alt="logo" />
					</a>
					<a className="navbar-brand brand-logo-mini" href={appLink.appRoot}>
						<img src="https://bitafrika.com/image/svg/logo-w.svg" alt="logo" />
					</a>
				</div>
				<div className="navbar-menu-wrapper d-flex align-items-center">
					<button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
						<span className="mdi mdi-menu" />
					</button>

					<ul className="navbar-nav navbar-nav-right">
						<li className="nav-item dropdown d-none d-xl-inline-block user-dropdown">
							<a className="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
								<div className="dropdown-toggle-wrapper">
									<div className="inner">
										<div className="inner">
											<span className="profile-text font-weight-bold">{account.fullName}</span>
											<small className="profile-text small">Admin</small>
										</div>
										<div className="inner">
											<div className="icon-wrapper">
												<i className="mdi mdi-chevron-down"></i>
											</div>
										</div>
									</div>
								</div>
							</a>

							<div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown" style={{ padding: 8, cursor: "pointer" }}>
								<a
									className="dropdown-item"
									href=""
									onClick={(e) => {
										e.preventDefault();
										logOffUser();
									}}
								>
									Sign Out
								</a>
							</div>
						</li>

						<li className="nav-item dropdown color-setting d-none d-md-block">
							<a className="nav-link count-indicator" href="#">
								<i className="mdi mdi-invert-colors" />
							</a>
						</li>
					</ul>
					<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
						<span className="mdi mdi-menu" />
					</button>
				</div>
			</nav>
		</React.Fragment>
	);
};

export default NavBarUi;
