import React from "react";
import Wrapper from "../misc/wrapper";
import ChipperPayments from "./chipperBody";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const ChipperPaymentsUI = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Chipper Payment Methods | BitAfrika</title>
			</Helmet>

			<Wrapper appLink={AppLinks}>
				<ChipperPayments appLink={AppLinks} />
			</Wrapper>
		</React.Fragment>
	);
};

export default ChipperPaymentsUI;
