/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import base64 from "base-64";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";
import { validate_amount } from "../../util/misc";

const AccWithdrawals = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);
	const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);

	const [topUpData, setTopUpData] = useState(null);
	const [wStatement, setWStatement] = useState("");

	const searchDate = (date) => {
		setLoaderShow(true);

		SendPostRequest(appLink.userAccountWithdrawalsEndpoint, {
			token: account.token,
			status: "success",
			show: date,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setTopUpData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	useEffect(() => {
		searchDate(currentDate);
	}, []);

	useEffect(() => {
		if (topUpData !== null) {
			let totalSoldGHS = 0;
			let totalSoldUSD = 0;
			let totalProfit = 0;

			for (let index = 0; index < topUpData.length; index++) {
				const tx = topUpData[index];

				totalProfit += Number(tx.netFee);

				totalSoldGHS += Number(tx.rawAmount);
				totalSoldUSD += Number(tx.usdAmount);
			}

			setWStatement(`Total Sold in GHS: ${validate_amount(totalSoldGHS, 2)} | Total Sold in USD: ${validate_amount(totalSoldUSD, 2)} | Profit: GHS ${validate_amount(totalProfit, 2)}`);
		}
	}, [topUpData]);

	const blockChainLink = (currency, transactionId, platform = "") => {
		currency = currency.toLowerCase();

		return currency === "btc"
			? `https://www.blockchain.com/btc/tx/${transactionId}`
			: currency === "tron"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "usdt"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "trx"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "bch"
			? `https://www.blockchain.com/bch/tx/${transactionId}`
			: currency === "ltc"
			? `https://chain.so/tx/LTC/${transactionId}`
			: currency === "eth"
			? `https://www.blockchain.com/eth/tx/${transactionId}`
			: currency === "bnb" || currency === "bsc"
			? `https://bscscan.com/tx/${transactionId}`
			: currency === "matic"
			? `https://polygonscan.com/tx/${transactionId}`
			: `https://chain.so/tx/DOGE/${transactionId}`;
	};

	const viewMethodInfo = (e, methodName, methodId) => {
		e.preventDefault();

		SendPostRequest(appLink.viewPaymentMethodEndpoint, {
			token: account.token,
			methodName: methodName,
			methodId: methodId,
		})
			.then(function (response) {
				if (response.data.success) {
					let payInfo = response.data.data;
					let message =
						methodName === "momo"
							? `Account: ${base64.decode(payInfo.name)}\n\nCountry: ${payInfo.countryCode}\nNetwork: ${payInfo.networkCode}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: methodName === "bank"
							? `Account Name: ${payInfo.accountName.length !== 0 ? base64.decode(payInfo.accountName) : "None"}\nAccount Number: ${payInfo.accountNumber.length !== 0 ? base64.decode(payInfo.accountNumber) : "None"}\n\nCountry: ${
									payInfo.countryCode
							  }\n\nBank Name: ${base64.decode(payInfo.bankName)}\nSwift / BiC: ${payInfo.swiftBic.length !== 0 ? base64.decode(payInfo.swiftBic) : "None"}\nRouting Number: ${
									payInfo.routingNumber.length !== 0 ? base64.decode(payInfo.routingNumber) : "None"
							  }\n\nPayment Method Status: ${payInfo.active ? "Active" : "Disabled"}\nRegistered on ${payInfo.createdOn}`
							: methodName === "chipper"
							? `Account: ${base64.decode(payInfo.chipperName)}\n\nCountry: ${payInfo.countryCode}\nChipper Username: ${base64.decode(payInfo.Username)}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: "Unknown Payment Method";

					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">
								Momo Withdrawals for: <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} />{" "}
								<button type="button" className="btn btn-primary" onClick={(e) => searchDate(currentDate)}>
									Search this Date
								</button>
								<br />
								<br />
								The list of successful account withdrawals performed on the platform - {topUpData !== null && topUpData.length} withdrawals
							</h4>
							{wStatement !== "" && <h3 className="mt-3 mb-3">{wStatement}</h3>}
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>TxId</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											{topUpData !== null &&
												topUpData.map((user, index) => (
													<tr key={index}>
														<td>
															<a href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																{user.username}
															</a>
														</td>
														<td>
															{user.uniq}
															<br />
															<small>{user.createdOn}</small>
															<br />
															<small>
																{user.paymentMethod} - {user.paymenthodMethodId}
															</small>
														</td>
														<td>
															{user.localCurrency} {user.rawAmount} ( {user.cryptoAmount} {user.cryptoCurrency} ) - ${user.usdAmount}
															<br />
															<small>
																Sold @ {user.rate} - ({user.cryptoPrice})
															</small>
															<br />
															<small>
																Received {user.localCurrency} {user.receivableLocal}
															</small>
															<br />
															{user.dataTwo === "tron" && (
																<a className="text-small" href={blockChainLink("tron", user.dataOne)} target="_blank" rel="noreferrer noopener">
																	{user.dataOne}
																</a>
															)}
															{user.dataTwo === "evm" && (
																<a className="text-small" href={blockChainLink(user.cryptoCurrency, user.dataOne)} target="_blank" rel="noreferrer noopener">
																	{user.dataOne}
																</a>
															)}
														</td>
														{/* <td>
															<button className="btn btn-primary mr-1" onClick={(e) => viewMethodInfo(e, user.methodName, user.methodId)}>
																View
															</button>
														</td> */}
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default AccWithdrawals;
