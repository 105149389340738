/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import base64 from "base-64";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const AccTopUps = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [topUpData, setTopUpData] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.userAccountTopUpsEndpoint, {
			token: account.token,
			status: "pending",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setTopUpData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const viewMethodInfo = (e, methodName, methodId) => {
		e.preventDefault();

		SendPostRequest(appLink.viewPaymentMethodEndpoint, {
			token: account.token,
			methodName: methodName,
			methodId: methodId,
		})
			.then(function (response) {
				if (response.data.success) {
					let payInfo = response.data.data;
					let message =
						methodName === "momo"
							? `Account: ${base64.decode(payInfo.name)}\n\nCountry: ${payInfo.countryCode}\nNetwork: ${payInfo.networkCode}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: methodName === "bank"
							? `Account Name: ${payInfo.accountName.length !== 0 ? base64.decode(payInfo.accountName) : "None"}\nAccount Number: ${payInfo.accountNumber.length !== 0 ? base64.decode(payInfo.accountNumber) : "None"}\n\nCountry: ${
									payInfo.countryCode
							  }\n\nBank Name: ${base64.decode(payInfo.bankName)}\nSwift / BiC: ${payInfo.swiftBic.length !== 0 ? base64.decode(payInfo.swiftBic) : "None"}\nRouting Number: ${
									payInfo.routingNumber.length !== 0 ? base64.decode(payInfo.routingNumber) : "None"
							  }\n\nPayment Method Status: ${payInfo.active ? "Active" : "Disabled"}\nRegistered on ${payInfo.createdOn}`
							: methodName === "chipper"
							? `Account: ${base64.decode(payInfo.chipperName)}\n\nCountry: ${payInfo.countryCode}\nChipper Username: ${base64.decode(payInfo.Username)}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: "Unknown Payment Method";

					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	const fundInfo = (e, id, username) => {
		e.preventDefault();

		SendPostRequest(appLink.fundUserAccountTopUpsEndpoint, {
			token: account.token,
			id: id,
			username: username,
		})
			.then(function (response) {
				if (response.data.success) {
					let message = "User has been funded and notified.";
					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">The list of pending transactions waiting for manual processing</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>TxId</th>
												<th>Amount (L)</th>
												<th>Amount ($)</th>
												<th>Amount (C)</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{topUpData !== null &&
												topUpData.map((user, index) => (
													<tr key={index}>
														<td>{user.username}</td>
														<td>{user.uniqId}</td>
														<td>
															{user.amount} {user.currency} ({user.methodName})
														</td>
														<td>${user.usd}</td>
														<td>
															{user.crypto} {user.cryptoSymbol}
														</td>
														<td>
															<button className="btn btn-primary mr-1" onClick={(e) => viewMethodInfo(e, user.methodName, user.methodId)}>
																View
															</button>
															<button className="btn btn-success mr-1" onClick={(e) => fundInfo(e, user.id, user.username)}>
																Fund
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default AccTopUps;
