/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import FormLoading from "../../loader/form_loading";
import { isUuid } from "uuidv4";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const SearchUserBody = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [userSearch, setUserSearch] = useState("");
	const [searchData, setSearchData] = useState(null);

	const initiateSearch = () => {
		setLoaderShow(true);

		SendPostRequest(appLink.searchTransactionEndpoint, {
			token: account.token,
			txid: userSearch,
			state: "active",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setSearchData(response.data.users);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const blockChainLink = (currency, transactionId) => {
		currency = currency.toLowerCase();

		return currency === "btc"
			? `https://www.blockchain.com/btc/tx/${transactionId}`
			: currency === "tron"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "usdt"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "trx"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "bch"
			? `https://www.blockchain.com/bch/tx/${transactionId}`
			: currency === "ltc"
			? `https://chain.so/tx/LTC/${transactionId}`
			: currency === "eth"
			? `https://www.blockchain.com/eth/tx/${transactionId}`
			: currency === "bnb" || currency === "bsc"
			? `https://bscscan.com/tx/${transactionId}`
			: currency === "matic"
			? `https://polygonscan.com/tx/${transactionId}`
			: `https://chain.so/tx/DOGE/${transactionId}`;
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body mb-3">
							<h4 className="card-title">Search for a transaction</h4>

							<div className="form-group mb-3">
								<input type="text" className="form-control" value={userSearch} placeholder="Search for transaction by transaction id, recipient address" onChange={(e) => setUserSearch(e.target.value)} />
							</div>

							<button
								className="btn btn-primary mr-3"
								onClick={(e) => {
									e.preventDefault();
									initiateSearch();
								}}
							>
								Search
							</button>

							{searchData !== null && (
								<React.Fragment>
									<div className="col-12 table-responsive">
										<table id="order-listing" className="table">
											<thead>
												<tr>
													<th>Type</th>
													<th>TxId</th>
													<th>Amount (C)</th>
													<th>Status</th>
													<th>Date</th>
												</tr>
											</thead>
											<tbody>
												{searchData !== null &&
													searchData.map((data, index) => (
														<tr key={index}>
															<td>
																{data.type === "receive" ? "R <= " : data.type === "send" ? "S => " : "U => "} {!isUuid(data.txid) ? "OUT" : "IN"}
															</td>
															<td>
																{data.type === "receive" ? (
																	!isUuid(data.txid) ? (
																		<a href={blockChainLink(data.currency, data.txid)} target="_blank" rel="noreferrer noopener">
																			{data.txid}
																		</a>
																	) : (
																		data.txid
																	)
																) : (
																	data.type === "send" &&
																	(!isUuid(data.txid) ? (
																		<a href={blockChainLink(data.currency, data.txid)} target="_blank" rel="noreferrer noopener">
																			{data.txid}
																		</a>
																	) : (
																		data.txid
																	))
																)}

																<br />
																{data.type === "send" && isUuid(data.txid) && (
																	<>
																		<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																			{data.username}
																		</a>
																		{" => "}
																		<a href={appLink.user.replace(":id", data.recipient)} target="_blank" rel="noopener noreferrer">
																			{data.recipient}
																		</a>
																	</>
																)}
																{data.type === "send" && !isUuid(data.txid) && (
																	<>
																		<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																			{data.username}
																		</a>
																		{" => "} {data.recipient}
																	</>
																)}
																{data.type === "receive" && isUuid(data.txid) && (
																	<>
																		<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																			{data.username}
																		</a>
																		{" <= "}
																		<a href={appLink.user.replace(":id", data.recipient)} target="_blank" rel="noopener noreferrer">
																			{data.recipient}
																		</a>
																	</>
																)}
																{data.type === "receive" && !isUuid(data.txid) && (
																	<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																		{data.username}
																	</a>
																)}
															</td>
															<td>
																{data.cryptoValue} {data.currency} ( USD {data.usdAmount} )
															</td>
															<td>{`${data.status} (${data.confirmations})`}</td>
															<td>{new Date(data.date).toDateString()}</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</React.Fragment>
							)}
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default SearchUserBody;
