/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { validate_amount } from "../../util/misc";
import { SendPostRequest } from "../../util/request";

const DashboardView = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [loaderShow, setLoaderShow] = useState(false);

	const [financialReport, setFinancialReport] = useState(null);

	const account = useStoreState((state) => state.account);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.financialReport, {
			token: account.token,
			currency: window.location.search.split("=")[1],
		})
			.then(function (response) {
				setLoaderShow(false);
				if (response.data.success) {
					setFinancialReport(response.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	return (
		<React.Fragment>
			<div className="card grid-margin">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{success !== null && (
					<div className="alert alert-success alert-fill-success alert-dismissible fade show" role="alert">
						{success}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							{financialReport !== null && (
								<>
									<h4 className="card-title">Platform Portfolio</h4>
									<div className="col-12">
										<div className="row">
											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Total {financialReport.currencyName}</h3>
															<span className="w-100">
																{validate_amount(financialReport.storageBTC)} {financialReport.currency} ({" "}
																{Number(validate_amount(parseFloat(financialReport.storageBTC) * parseFloat(financialReport.userBTCUsd), 2)).toLocaleString("en-US", {
																	style: "currency",
																	currency: "USD",
																})}{" "}
																)
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>User {financialReport.currencyName}</h3>
															<span className="w-100">
																{validate_amount(financialReport.userBTC)} {financialReport.currency} ({" "}
																{Number(validate_amount(parseFloat(financialReport.userBTC) * parseFloat(financialReport.userBTCUsd), 2)).toLocaleString("en-US", {
																	style: "currency",
																	currency: "USD",
																})}{" "}
																)
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Our {financialReport.currencyName}</h3>
															<span className="w-100">
																{validate_amount(financialReport.businessWallet)} {financialReport.currency}({" "}
																{Number(validate_amount(parseFloat(financialReport.businessWallet) * parseFloat(financialReport.userBTCUsd), 2)).toLocaleString("en-US", {
																	style: "currency",
																	currency: "USD",
																})}{" "}
																)
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row mt-3">
											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Total Sold ({financialReport.sell.totalTx})</h3>
															<span className="w-100">
																Crypto Sold : {validate_amount(financialReport.sell.txCryptoAmount)} {financialReport.currency}({" "}
																{Number(validate_amount(financialReport.sell.txUSDAmount), 2).toLocaleString("en-US", {
																	style: "currency",
																	currency: "USD",
																})}{" "}
																)
															</span>
															<span className="w-100">
																Total Paid :{" "}
																{Number(financialReport.sell.txReceivableLocal).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
															</span>
															<span className="w-100">
																Total Profit :{" "}
																{Number(financialReport.sell.txProfit).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
															</span>
														</div>
													</div>
												</div>
											</div>

											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Transactions ({financialReport.transactions.totalTx})</h3>
															<span className="w-100">Total Sent : {financialReport.transactions.txCount}</span>
															<span className="w-100">Total Received : {financialReport.transactions.rxCount}</span>
														</div>
													</div>
												</div>
											</div>

											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Momo Available</h3>
															<span className="w-100">
																Avl. Balance:{" "}
																{Number(financialReport.momoStorage.available_balance).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
																<br />
																Total Fees:{" "}
																{Number(financialReport.momoStorage.fees).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
																<br />
																Total Deposit:{" "}
																{Number(financialReport.momoStorage.revenue).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
																<br />
																Total Withdrawals:{" "}
																{Number(financialReport.momoStorage.redrawals).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
																<br />
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="row mt-3">
											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Total Top Ups ({financialReport.topUps.totalTx})</h3>
															<span className="w-100">
																Crypto Bought : {validate_amount(financialReport.topUps.txCryptoAmount)} {financialReport.currency} ({" "}
																{Number(validate_amount(financialReport.topUps.txUSDAmount), 2).toLocaleString("en-US", {
																	style: "currency",
																	currency: "USD",
																})}{" "}
																)
															</span>
															<span className="w-100">
																GHS Equiv :{" "}
																{Number(financialReport.topUps.txAmount).toLocaleString("en-US", {
																	style: "currency",
																	currency: "GHS",
																})}
															</span>
														</div>
													</div>
												</div>
											</div>

											<div className="col-4">
												<div className="col-12">
													<div className="row">
														<div className="card card-body">
															<h3>Total Users ({financialReport.users.totalUsers})</h3>
															<span className="w-100">Total Verified : {financialReport.users.totalVerified}</span>
															<span className="w-100">Total Unverified : {financialReport.users.totalUnverified}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							)}
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default DashboardView;
