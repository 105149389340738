/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const SiteConfiguration = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [loaderShow, setLoaderShow] = useState(false);

	const [siteConfig, setSiteConfigValues] = useState(null);

	const account = useStoreState((state) => state.account);

	const DataPresenter = (props) => {
		const data = props.data;

		const [initialValue, setValue] = useState(data.value);
		const [currentValue, setCurrentValue] = useState(data.value);

		const [updating, isUpdating] = useState(false);
		const [updated, isUpdated] = useState(false);

		const updateConfig = (e, id, value) => {
			e.preventDefault();

			isUpdating(true);
			isUpdated(false);

			SendPostRequest(appLink.updateSiteConfig, {
				token: account.token,
				[id]: value,
				name: id,
				value,
			})
				.then(function (response) {
					isUpdating(false);

					if (response.data.success) {
						setValue(value);
						isUpdated(true);
						setCurrentValue(value);
					} else {
						window.swal({
							text: response.data.message,
							button: {
								text: "OK",
								value: true,
								visible: true,
								className: "btn btn-primary",
							},
						});
					}
				})
				.catch((error) => {
					isUpdating(false);
					isUpdated(false);

					alert("Unable to connect to server. Check Internet");
				});
		};

		return data.valueType.toLowerCase() === "percent" ? (
			<div className="form-group mb-3">
				<label>
					{data.title} {updated && <span className="text-success"> - Updated</span>}
				</label>

				<div className="input-group">
					<div className="input-group-prepend bg-primary border-primary">
						<span className="input-group-text bg-transparent text-white">%</span>
					</div>
					<input
						type="number"
						className="form-control"
						min={0}
						max={100}
						value={initialValue}
						onChange={(e) => {
							e.preventDefault();
							setValue(e.target.value);
						}}
						disabled={!data.editable}
					/>
					{data.editable && (
						<div className="input-group-append">
							{updating ? (
								<button type="button" className="btn btn-default">
									Updating ...
								</button>
							) : (
								<button onClick={(e) => updateConfig(e, data.name, initialValue)} type="button" className="btn btn-primary">
									Update
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		) : data.valueType.toLowerCase() === "number" ? (
			<div className="form-group mb-3">
				<label>
					{data.title} {updated && <span className="text-success">- Updated</span>}
				</label>

				<div className="input-group">
					<input
						type="number"
						className="form-control"
						min={0}
						value={initialValue}
						onChange={(e) => {
							e.preventDefault();
							setValue(e.target.value);
						}}
						disabled={!data.editable}
					/>
					{data.editable && (
						<div className="input-group-append">
							{updating ? (
								<button type="button" className="btn btn-default">
									Updating ...
								</button>
							) : (
								<button onClick={(e) => updateConfig(e, data.name, initialValue)} type="button" className="btn btn-primary">
									Update
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		) : data.valueType.toLowerCase() === "bool" ? (
			<div className="form-group mb-3">
				<label>
					{data.title} {currentValue.toLowerCase() === "true" ? <span className="text-success">- Enabled</span> : <span className="text-danger">- Disabled</span>}
				</label>

				<div className="input-group">
					<select
						type="number"
						className="form-control"
						min={0}
						value={initialValue}
						onChange={(e) => {
							e.preventDefault();
							setValue(e.target.value);
						}}
						disabled={!data.editable}
					>
						<option value={"TRUE"}>Enable - TURN ON</option>
						<option value={"FALSE"}>Disable - TURN OFF</option>
					</select>
					{data.editable && (
						<div className="input-group-append">
							{updating ? (
								<button type="button" className="btn btn-default">
									Updating ...
								</button>
							) : (
								<button onClick={(e) => updateConfig(e, data.name, initialValue)} type="button" className="btn btn-primary">
									Update
								</button>
							)}
						</div>
					)}
				</div>
				{updated && <span className="text-success"> - Updated</span>}
			</div>
		) : (
			<div className="form-group mb-3">
				<label>
					{data.title} {updated && <span className="text-success"> - Updated</span>}
				</label>

				<div className="input-group">
					<input
						type="text"
						className="form-control"
						value={initialValue}
						onChange={(e) => {
							e.preventDefault();
							setValue(e.target.value);
						}}
						disabled={!data.editable}
					/>
					{data.editable && (
						<div className="input-group-append">
							{updating ? (
								<button type="button" className="btn btn-default">
									Updating ...
								</button>
							) : (
								<button onClick={(e) => updateConfig(e, data.name, initialValue)} type="button" className="btn btn-primary">
									Update
								</button>
							)}
						</div>
					)}
				</div>
			</div>
		);
	};

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.siteConfig, {
			token: account.token,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setSiteConfigValues(response.data.misc);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	return (
		<React.Fragment>
			<div className="card grid-margin">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{success !== null && (
					<div className="alert alert-success alert-fill-success alert-dismissible fade show" role="alert">
						{success}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow && siteConfig !== null ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">Site Configuration</h4>

							<div className="col-12">
								<div className="row">
									{siteConfig.map((data) => (
										<div className="col-4 mb-3">
											<DataPresenter data={data} />
											<span className="text-small text-muted">{data.description}</span>
										</div>
									))}
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default SiteConfiguration;
