/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import { isUuid } from "uuidv4";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";
import { validate_amount } from "../../util/misc";

const AccTopUps = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);

	const [transactionData, setTransactionData] = useState(null);

	const [txStatement, setTxStatement] = useState("");

	const searchDate = (date) => {
		setLoaderShow(true);

		SendPostRequest(appLink.transactionsEndpoint, {
			token: account.token,
			show: date,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setTransactionData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};
	useEffect(() => {
		searchDate(currentDate);
	}, []);

	useEffect(() => {
		if (transactionData !== null) {
			let totalPendingCount = 0;
			let totalReceivedCount = 0;
			let totalSentCount = 0;

			let totalPendingUSDAmount = 0;
			let totalReceivedAmount = 0;
			let totalSendAmount = 0;

			for (let index = 0; index < transactionData.length; index++) {
				const tx = transactionData[index];

				if (tx.status === "pending") {
					totalPendingUSDAmount += Number(tx.usdAmount);
					totalPendingCount += 1;
				} else if (tx.status === "confirmed") {
					totalReceivedAmount += Number(tx.usdAmount);
					totalReceivedCount += 1;
				} else if (tx.status === "success") {
					totalSendAmount += Number(tx.usdAmount);
					totalSentCount += 1;
				}
			}

			setTxStatement(
				`Total Pending in USD: ${validate_amount(totalPendingUSDAmount, 2)} (${totalPendingCount}) | Total Received in USD: ${validate_amount(totalReceivedAmount, 2)} (${totalReceivedCount}) | Total Sent in USD: ${validate_amount(
					totalSendAmount,
					2
				)} (${totalSentCount})`
			);
		}
	}, [transactionData]);

	const blockChainLink = (currency, transactionId, platform = "") => {
		currency = currency.toLowerCase();

		return currency === "btc"
			? `https://www.blockchain.com/btc/tx/${transactionId}`
			: currency === "tron"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "usdt"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "trx"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "bch"
			? `https://www.blockchain.com/bch/tx/${transactionId}`
			: currency === "ltc"
			? `https://chain.so/tx/LTC/${transactionId}`
			: currency === "eth"
			? `https://www.blockchain.com/eth/tx/${transactionId}`
			: currency === "bnb" || currency === "bsc"
			? `https://bscscan.com/tx/${transactionId}`
			: currency === "matic"
			? `https://polygonscan.com/tx/${transactionId}`
			: `https://chain.so/tx/DOGE/${transactionId}`;
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">
								Transactions for: <input type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} />{" "}
								<button type="button" className="btn btn-primary" onClick={(e) => searchDate(currentDate)}>
									Search this Date
								</button>
								<br />
								<br />
								The list of transactions performed on the platform {transactionData !== null && `( ${transactionData.length} Transactions )`}
							</h4>
							{txStatement !== "" && <h4 className="mt-3 mb-3">{txStatement}</h4>}
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table dataTable">
										<thead>
											<tr>
												<th>Type</th>
												<th>TxId</th>
												<th>Info</th>
											</tr>
										</thead>
										<tbody>
											{transactionData !== null &&
												transactionData.map((data, index) => (
													<tr key={index}>
														<td>
															{data.type === "receive" ? "R <= " : data.type === "send" ? "S => " : "U => "} {!isUuid(data.txid) ? "OUT" : "IN"}
														</td>
														<td>
															{data.type === "receive" ? (
																!isUuid(data.txid) ? (
																	<a href={blockChainLink(data.currency, data.txid)} target="_blank" rel="noreferrer noopener">
																		{data.txid}
																	</a>
																) : (
																	data.txid
																)
															) : (
																data.type === "send" &&
																(!isUuid(data.txid) ? (
																	<a href={blockChainLink(data.currency, data.txid)} target="_blank" rel="noreferrer noopener">
																		{data.txid}
																	</a>
																) : (
																	data.txid
																))
															)}

															<br />
															{data.type === "send" && isUuid(data.txid) && (
																<>
																	<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																		{data.username}
																	</a>
																	{" => "}
																	<a href={appLink.user.replace(":id", data.recipient)} target="_blank" rel="noopener noreferrer">
																		{data.recipient}
																	</a>
																</>
															)}
															{data.type === "send" && !isUuid(data.txid) && (
																<>
																	<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																		{data.username}
																	</a>
																	{" => "} {data.recipient}
																</>
															)}
															{data.type === "receive" && isUuid(data.txid) && (
																<>
																	<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																		{data.username}
																	</a>
																	{" <= "}
																	<a href={appLink.user.replace(":id", data.recipient)} target="_blank" rel="noopener noreferrer">
																		{data.recipient}
																	</a>
																</>
															)}
															{data.type === "receive" && !isUuid(data.txid) && (
																<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
																	{data.username}
																</a>
															)}
															<br />
															<span className="text-muted text-small">{data.date}</span>
														</td>
														<td>
															{data.cryptoValue} {data.currency} ( USD {data.usdAmount} )<br />
															{`${data.status} (${data.confirmations})`}
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default AccTopUps;
