/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import FormLoading from "../../loader/form_loading";
import base64 from "base-64";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const SearchBody = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [userSearch, setUserSearch] = useState("");
	const [searchData, setSearchData] = useState(null);

	const initiateSearch = () => {
		setLoaderShow(true);

		SendPostRequest(appLink.searchWithdrawalEndpoint, {
			token: account.token,
			txid: userSearch,
			state: "active",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setSearchData(response.data.users);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const viewMethodInfo = (e, methodName, methodId) => {
		e.preventDefault();

		SendPostRequest(appLink.viewPaymentMethodEndpoint, {
			token: account.token,
			methodName: methodName,
			methodId: methodId,
		})
			.then(function (response) {
				if (response.data.success) {
					let payInfo = response.data.data;
					let message =
						methodName === "momo"
							? `Account: ${base64.decode(payInfo.name)}\n\nCountry: ${payInfo.countryCode}\nNetwork: ${payInfo.networkCode}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: methodName === "bank"
							? `Account Name: ${payInfo.accountName.length !== 0 ? base64.decode(payInfo.accountName) : "None"}\nAccount Number: ${payInfo.accountNumber.length !== 0 ? base64.decode(payInfo.accountNumber) : "None"}\n\nCountry: ${
									payInfo.countryCode
							  }\n\nBank Name: ${base64.decode(payInfo.bankName)}\nSwift / BiC: ${payInfo.swiftBic.length !== 0 ? base64.decode(payInfo.swiftBic) : "None"}\nRouting Number: ${
									payInfo.routingNumber.length !== 0 ? base64.decode(payInfo.routingNumber) : "None"
							  }\n\nPayment Method Status: ${payInfo.active ? "Active" : "Disabled"}\nRegistered on ${payInfo.createdOn}`
							: methodName === "chipper"
							? `Account: ${base64.decode(payInfo.chipperName)}\n\nCountry: ${payInfo.countryCode}\nChipper Username: ${base64.decode(payInfo.Username)}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: "Unknown Payment Method";

					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body mb-3">
							<h4 className="card-title">Search for Withdrawal</h4>

							<div className="form-group mb-3">
								<input type="text" className="form-control" value={userSearch} placeholder="Search for withdrawal by reference id" onChange={(e) => setUserSearch(e.target.value)} />
							</div>

							<button
								className="btn btn-primary mr-3"
								onClick={(e) => {
									e.preventDefault();
									initiateSearch();
								}}
							>
								Search
							</button>

							{searchData !== null && (
								<React.Fragment>
									<div className="col-12 table-responsive">
										<table id="order-listing" className="table dataTable no-footer" role="grid" aria-describedby="order-listing_info">
											<thead>
												<tr>
													<th>Username</th>
													<th>TxId</th>
													<th>Amount (L)</th>
													<th>Amount ($)</th>
													<th>Amount (C)</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{searchData !== null && (
													<tr>
														<td>
															<a href={appLink.user.replace(":id", searchData.username)} target="_blank" rel="noopener noreferrer">
																{searchData.username}
															</a>
														</td>
														<td>
															{searchData.uniq}
															<br />
															<small>{searchData.createdOn}</small>
														</td>
														<td>
															{searchData.localCurrency} {searchData.receivableLocal}
														</td>
														<td>${searchData.usdAmount}</td>
														<td>
															{searchData.cryptoAmount} {searchData.cryptoCurrency}
														</td>
														<td>
															<button className="btn btn-primary mr-1" onClick={(e) => viewMethodInfo(e, searchData.paymentMethod, searchData.paymenthodMethodId)}>
																View
															</button>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</React.Fragment>
							)}
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default SearchBody;
