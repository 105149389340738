/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import base64 from "base-64";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const AccTopUps = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [withdrawalData, setTopUpData] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.viewPendingWithdrawals, {
			token: account.token,
			status: "pending",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setTopUpData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const updateWithdrawalStatus = (e, username, id) => {
		e.preventDefault();

		SendPostRequest(appLink.updateWithdrawalStatus, {
			token: account.token,
			username: username,
			id: id,
			status: "success",
		})
			.then(function (response) {
				if (response.data.success) {
					const activeUsers = withdrawalData.filter((user) => user.id !== id);
					setTopUpData(activeUsers);

					window.swal({
						text: `The withdrawal process for (${username}) was successful.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">The list of pending withdrawals waiting for manual processing</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>TxId</th>
												<th>Amount</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{withdrawalData !== null &&
												withdrawalData.map((user, index) => (
													<tr key={index}>
														<td>
															<a href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																{user.username}
															</a>
														</td>
														<td>
															{user.uniq}
															<br />

															{user.paymentMethod === "momo" ? (
																<small>
																	Account: {base64.decode(user.payInfo.name)} | Network: {user.payInfo.networkCode} | Phone Number: {user.payInfo.phoneNumber}
																</small>
															) : user.paymentMethod === "bank" ? (
																<small>
																	Account Name: {user.payInfo.accountName.length !== 0 ? base64.decode(user.payInfo.accountName) : "None"} | Account Number:
																	{user.payInfo.accountNumber.length !== 0 ? base64.decode(user.payInfo.accountNumber) : "None"}
																	<br />
																	Bank Name: {user.payInfo.bankName}
																</small>
															) : (
																<small>Unknown Payment Method</small>
															)}
														</td>
														<td>
															{user.localCurrency} {user.receivableLocal} ( ${user.usdAmount} )<br />
															<small>
																{user.cryptoAmount} {user.cryptoCurrency}
															</small>
														</td>
														<td>
															<button className="btn btn-sm btn-success mr-1" onClick={(e) => updateWithdrawalStatus(e, user.username, user.id)}>
																Mark as Success
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default AccTopUps;
