import React from 'react';
import Wrapper from "../misc/wrapper";
import UsersBody from "./body";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const DisabledUsersList = () => {
    return (
        <React.Fragment>

            <Helmet>
                <title>Disabled Users | BitAfrika</title>
            </Helmet>

            <Wrapper appLink={AppLinks}>
                <UsersBody appLink={AppLinks} />
            </Wrapper>
        </React.Fragment>
    )
}

export default DisabledUsersList;