const passwordValidator = (key) => key.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);

const convertExponentialToDecimal = (exponentialNumber) => {
	// sanity check - is it exponential number
	const str = exponentialNumber.toString();
	if (str.indexOf("e") !== -1) {
		// Unfortunately I can not return 1e-8 as 0.00000001, because even if I call parseFloat() on it,
		// it will still return the exponential representation
		// So I have to use .toFixed()
		const result = parseFloat(exponentialNumber).toFixed(8);
		return result;
	} else {
		return exponentialNumber;
	}
};

const validate_amount = (amount, fixedLength = 8) => {
	// Assumes Amount is a Positive Number
	// Split amount into two ( Whole && Dec)
	amount = convertExponentialToDecimal(parseFloat(amount));
	amount = `${amount}`;
	const dotIndex = amount.indexOf(".");

	if (dotIndex === -1) {
		// Complete whole number
		amount = amount + "." + "".padEnd(fixedLength, "0");
	} else {
		const whole = amount.substring(0, dotIndex);
		let dec = amount.substring(dotIndex + 1);
		dec = dec.length > fixedLength ? dec.substring(0, fixedLength) : dec.padEnd(fixedLength, "0");
		amount = whole + "." + dec;
	}

	return Number(parseFloat(amount).toFixed(fixedLength));
};

export { passwordValidator, validate_amount };
