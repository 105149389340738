/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { passwordValidator } from "../../util/misc";
import { SendPostRequest } from "../../util/request";

const SiteConfiguration = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [loaderShow, setLoaderShow] = useState(false);
	const [configValues, setConfigValues] = useState({
		firstName: "",
		lastName: "",
		username: "",
		password: "",
		criticalPermission: false,
		viewPermission: true,
	});

	const account = useStoreState((state) => state.account);
	const [users, setUsers] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.listAdmin, {
			token: account.token,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setUsers(response.data.admin);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const createAdmin = (e) => {
		e.preventDefault();

		if (configValues.username.length < 3) {
			setError("Your username is invalid. Enter 3 or more characters");
			return;
		}

		if (!passwordValidator(configValues.password)) {
			setError("Password should be between 6 to 20 characters and contain at least one numeric digit, one uppercase and one lowercase letter");
			return;
		}

		SendPostRequest(appLink.createAdmin, {
			token: account.token,
			...configValues,
		})
			.then(function (response) {
				if (response.data.success) {
					setConfigValues({
						firstName: "",
						lastName: "",
						username: "",
						password: "",
						criticalPermission: false,
						viewPermission: true,
					});
					setSuccess("Admin account have been created successfully. Reload Page");
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const deleteAdmin = (e, id, username) => {
		e.preventDefault();

		SendPostRequest(appLink.deleteAdmin, {
			token: account.token,
			id: id,
			username: username,
		})
			.then(function (response) {
				if (response.data.success) {
					const activeUsers = users.filter((user) => user.username !== username);
					setUsers(activeUsers);

					window.swal({
						text: `The admin with username (${username}) has been deleted. Reload page.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card grid-margin">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{success !== null && (
					<div className="alert alert-success alert-fill-success alert-dismissible fade show" role="alert">
						{success}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<form className="forms-sample" onSubmit={createAdmin}>
								<div className="col-12">
									<h4 className="card-title">Add New Admin</h4>

									<div className="row">
										<div className="col-sm-12 col-md-4 col-lg-4">
											<div className="form-group mb-3">
												<label>First Name</label>
												<input
													type="text"
													className="form-control"
													required={true}
													value={configValues.firstName}
													onChange={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															firstName: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="col-sm-12 col-md-4 col-lg-4">
											<div className="form-group mb-3">
												<label>Last Name</label>
												<input
													type="text"
													className="form-control"
													required={true}
													value={configValues.lastName}
													onChange={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															lastName: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="col-sm-12 col-md-4 col-lg-4">
											<div className="form-group mb-3">
												<label>Username</label>
												<input
													type="text"
													className="form-control"
													required={true}
													value={configValues.username}
													onChange={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															username: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="col-sm-12 col-md-4 col-lg-4">
											<div className="form-group mb-3">
												<label>Password</label>
												<input
													type="password"
													className="form-control"
													required={true}
													value={configValues.password}
													onChange={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															password: e.target.value,
														});
													}}
												/>
											</div>
										</div>

										<div className="col-sm-12 col-md-4 col-lg-4">
											<div className="form-group mb-3">
												<label>Read Only</label>
												<br />
												<input
													type="radio"
													checked={configValues.viewPermission}
													onChange={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															viewPermission: !configValues.viewPermission,
														});
													}}
												/>{" "}
												Read Only Access
											</div>
										</div>

										<div className="col-sm-12 col-md-4 col-lg-4">
											<div className="form-group mb-3">
												<label>Super Admin</label>
												<br />

												<button
													className="btn btn-success mr-2"
													onClick={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															criticalPermission: true,
														});
													}}
												>
													Yes
												</button>

												<button
													className="btn btn-danger mr-2"
													onClick={(e) => {
														e.preventDefault();
														setConfigValues({
															...configValues,
															criticalPermission: false,
														});
													}}
												>
													No
												</button>

												{configValues.criticalPermission ? "Access Granted" : "Access Denied"}
											</div>
										</div>
									</div>
								</div>

								<button type="submit" className="btn btn-primary mr-2">
									Create Admin
								</button>
							</form>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>

			<div className="card">
				<div className="card-body">
					<h4 className="card-title">Admin Account</h4>
					<div className="row">
						<div className="col-12">
							<table className="table">
								<thead>
									<tr>
										<th>Username</th>
										<th>Name</th>
										<th>Permission</th>
										<th>Date</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{users !== null &&
										users.map((user, index) => (
											<tr key={index} className="odd">
												<td>{user.username}</td>
												<td>
													{user.lastName} {user.firstName}
												</td>
												<td>
													ViewUser: {user.viewPermission ? "Yes" : "No"} <br />
													SuperUser: {user.criticalPermission ? "Yes" : "No"}
												</td>
												<td>{user.createdOn}</td>
												<td>
													<button className="btn btn-danger mr-1" onClick={(e) => deleteAdmin(e, user.id, user.username)}>
														Delete
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SiteConfiguration;
