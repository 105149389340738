import React from "react";
import { useStoreState } from "easy-peasy";

const SideBarUi = ({ appLink }) => {
	const account = useStoreState((state) => state.account);

	return (
		<React.Fragment>
			<nav className="sidebar sidebar-offcanvas sidebar-dark" id="sidebar">
				<ul className="nav">
					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#dashboard-layouts" aria-expanded="false" aria-controls="dashboard-layouts">
							<i className="menu-icon icon-grid" />
							<span className="menu-title">Dashboard</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="dashboard-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.dashboard}?currency=btc`}>
										Bitcoin
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.dashboard}?currency=ltc`}>
										Litecoin
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.dashboard}?currency=doge`}>
										Dogecoin
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.dashboard}?currency=bch`}>
										Bitcoin Cash
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.tronBoard}?currency=trx`}>
										TRON - TRX
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.tronBoard}?currency=usdt`}>
										TRON - USDT
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.tronBoard}?currency=usdc`}>
										TRON - USDC
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.evmBoard}?currency=eth`}>
										ETHEREUM
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.evmBoard}?currency=matic`}>
										POLYGON - MATIC
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={`${appLink.evmBoard}?currency=bsc`}>
										BINANCE COIN
									</a>
								</li>
							</ul>
						</div>
					</li>

					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#search-layouts" aria-expanded="false" aria-controls="search-layouts">
							<i className="menu-icon icon-link" />
							<span className="menu-title">Search</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="search-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.searchUsers}>
										User
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.searchMomoDeposit}>
										Momo Deposit
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.searchMomoWithdrawal}>
										Momo Withdrawal
									</a>
								</li>

								<li className="nav-item">
									<a className="nav-link" href={appLink.searchTransaction}>
										Crypto Transaction
									</a>
								</li>
							</ul>
						</div>
					</li>

					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#users-layouts" aria-expanded="false" aria-controls="users-layouts">
							<i className="menu-icon icon-people" />
							<span className="menu-title">Users</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="users-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.users}>
										Registered
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.kyc}>
										Verified
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.errorKYC}>
										Rejected KYC
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.usersInActive}>
										Hold
									</a>
								</li>
							</ul>
						</div>
					</li>
					{/*
					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#topups-layouts" aria-expanded="false" aria-controls="topups-layouts">
							<i className="menu-icon icon-wallet" />
							<span className="menu-title">Account Top Ups</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="topups-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.accountTopUps}>
										Success
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.accountTopUpsManual}>
										Manual
									</a>
								</li>
							</ul>
						</div>
					</li>

					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#kyc-layouts" aria-expanded="false" aria-controls="kyc-layouts">
							<i className="menu-icon icon-ghost" />
							<span className="menu-title">KYC</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="kyc-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.errorKYC}>
										Failed
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.kyc}>
										Success
									</a>
								</li>
							</ul>
						</div>
					</li>

					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#pay-layouts" aria-expanded="false" aria-controls="pay-layouts">
							<i className="menu-icon icon-list" />
							<span className="menu-title">Payment Accounts</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="pay-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.bank}>
										Bank
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.chipper}>
										ChipperCash
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.momo}>
										Mobile Money
									</a>
								</li>
							</ul>
						</div>
					</li>*/}

					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#withdrawals-layouts" aria-expanded="false" aria-controls="withdrawals-layouts">
							<i className="menu-icon icon-ghost" />
							<span className="menu-title">Manual Approvals</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="withdrawals-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.manualTopup}>
										Manual TOP UP - NEW
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.pendingKYC}>
										KYC
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.accountTopUpsManual}>
										Top Up
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.withdrawalsPending}>
										Withdrawal
									</a>
								</li>
							</ul>
						</div>
					</li>

					<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#users-tx" aria-expanded="false" aria-controls="users-tx">
							<i className="menu-icon icon-link" />
							<span className="menu-title">Transactions</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="users-tx">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.transactions}>
										Crypto
									</a>
								</li>

								<li className="nav-item">
									<a className="nav-link" href={appLink.accountTopUps}>
										Momo Top Ups
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.withdrawals}>
										Momo Withdrawals
									</a>
								</li>
							</ul>
						</div>
					</li>

					{/*<li className="nav-item">
						<a className="nav-link" data-toggle="collapse" href="#sms-layouts" aria-expanded="false" aria-controls="sms-layouts">
							<i className="menu-icon icon-target" />
							<span className="menu-title">Broadcast</span>
							<div className="badge">▽</div>
						</a>
						<div className="collapse" id="sms-layouts">
							<ul className="nav flex-column sub-menu">
								<li className="nav-item">
									<a className="nav-link" href={appLink.broadcast}>
										Send SMS
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href={appLink.emailBroadcast}>
										Send Email
									</a>
								</li>
							</ul>
						</div>
				</li>*/}

					{/*
					

					<li className="nav-item">
						<a className="nav-link" href={appLink.addresses}>
							<i className="menu-icon icon-notebook" />
							<span className="menu-title">Addresses</span>
						</a>
					</li>

					<li className="nav-item">
						<a className="nav-link" href={appLink.airTimeTopUps}>
							<i className="menu-icon icon-screen-smartphone" />
							<span className="menu-title">AirTime Top Ups</span>
						</a>
	</li>*/}

					<li className="nav-item">
						<a className="nav-link" href={appLink.countries}>
							<i className="menu-icon icon-globe" />
							<span className="menu-title">Countries</span>
						</a>
					</li>

					{/*<li className="nav-item">
						<a className="nav-link" href={appLink.balance}>
							<i className="menu-icon icon-diamond" />
							<span className="menu-title">Balances</span>
						</a>
</li>*/}

					<li className="nav-item">
						<a className="nav-link" href={appLink.site}>
							<i className="menu-icon icon-equalizer" />
							<span className="menu-title">Site Info</span>
						</a>
					</li>

					{account.criticalPermission && (
						<li className="nav-item">
							<a className="nav-link" href={appLink.admin}>
								<i className="menu-icon icon-fire" />
								<span className="menu-title">Admin</span>
							</a>
						</li>
					)}
				</ul>
			</nav>
		</React.Fragment>
	);
};

export default SideBarUi;
