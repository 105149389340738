/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import Lightbox from "react-image-lightbox";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const KYCList = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [users, setUsers] = useState(null);

	const [photos, setPhotos] = useState(null);
	const [photoIndex, setPhotoIndex] = useState(0);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.kycEndpoint, {
			token: account.token,
			status: "success",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setUsers(response.data.kyc);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const updataKYCStatus = (e, username, status) => {
		e.preventDefault();

		SendPostRequest(appLink.updateUserEndpoint, {
			token: account.token,
			username: username,
			status: "error",
			reason: status,
		})
			.then(function (response) {
				if (response.data.success) {
					const activeUsers = users.filter((user) => user.username !== username);
					setUsers(activeUsers);

					window.swal({
						text: `The kyc documents for account (${username}) has been rejected.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const viewPhotos = (e, data) => {
		e.preventDefault();
		setPhotos(data);
	};

	const closePhotos = () => {
		setPhotos(null);
		setPhotoIndex(0);
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						{photos !== null && (
							<Lightbox
								mainSrc={photos[photoIndex]}
								nextSrc={photos[(photoIndex + 1) % photos.length]}
								prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
								onCloseRequest={() => closePhotos()}
								onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
								onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
							/>
						)}
						<div className="card-body">
							<h4 className="card-title">The list of verified users {users !== null && `( ${users.length} Users )`}</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>Photo</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{users !== null &&
												users.map((user, index) => (
													<tr key={index}>
														<td>
															<a href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																{user.username}
															</a>
														</td>
														<td>
															<a
																onClick={(e) => viewPhotos(e, [atob(user.photoId), atob(user.SelfieId), atob(user.CardId)])}
																className="btn btn-primary mr-1"
																rel="noreferrer noopener"
																href={`${appLink.serverBaseEndpoint}admin_kyc/${user.photoId}`}
																target="_blank"
															>
																View
															</a>
														</td>

														<td>
															<button
																className="btn btn-outline-danger"
																onClick={(e) => {
																	let reason = prompt("Reason for disapproving user kyc. This is shown to the user to help them do better when re-uploading their kyc");

																	if (reason === null || reason === undefined) {
																		window.swal({
																			text: "You need to provide a reason for suspending a valid and approved KYC document",
																			button: {
																				text: "OK",
																				value: true,
																				visible: true,
																				className: "btn btn-primary",
																			},
																		});
																		return;
																	}

																	updataKYCStatus(e, user.username, reason);
																}}
															>
																Decline Status
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default KYCList;
