/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import base64 from "base-64";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const MomoPayments = ({ appLink }) => {
	const [error, setError] = useState(null);

	const [loaderShow, setLoaderShow] = useState(false);
	const [methodData, setMethodData] = useState(null);

	const account = useStoreState((state) => state.account);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.paymentMethods, {
			token: account.token,
			method: "bank",
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setMethodData(response.data.methodData);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const deactivatePayment = (e, user, status) => {
		e.preventDefault();

		SendPostRequest(appLink.updateMethod, {
			token: account.token,
			id: user.id,
			active: status,
			username: user.username,
			method: "bank",
		})
			.then(function (response) {
				if (response.data.success) {
					window.swal({
						text: `Payment method for ${user.username} has been ${status ? "enabled" : "disabled"}. Reload page to see changes`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">Users and their bank payment methods</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>Country</th>
												<th>Bank</th>
												<th>Account</th>
												<th>Number</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{methodData !== null &&
												methodData.map((user, index) => (
													<tr key={index}>
														<td>{user.username}</td>
														<td>{user.countryCode}</td>
														<td>{user.bankName}</td>
														<td>{base64.decode(user.accountName)}</td>
														<td>{base64.decode(user.accountNumber)}</td>
														<td>
															{user.active ? (
																<button className="btn btn-danger mr-1" onClick={(e) => deactivatePayment(e, user, false)}>
																	Deactivate
																</button>
															) : (
																<button className="btn btn-success mr-1" onClick={(e) => deactivatePayment(e, user, true)}>
																	Activate
																</button>
															)}
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default MomoPayments;
