/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const SiteConfiguration = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [loaderShow, setLoaderShow] = useState(false);
	const [smsMessage, setSMSMessage] = useState("");
	const [subject, setSubject] = useState("");

	const account = useStoreState((state) => state.account);

	const sendBroadCast = (e) => {
		e.preventDefault();

		setLoaderShow(true);
		setSuccess(null);
		setError(null);

		SendPostRequest(appLink.sendEmailBroadcastEndpoint, {
			token: account.token,
			message: smsMessage,
			subject,
		})
			.then(function (response) {
				setLoaderShow(false);
				if (response.data.success) {
					setSuccess(response.data.message);
					setSMSMessage("");
					setSubject("");
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card grid-margin">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{success !== null && (
					<div className="alert alert-success alert-fill-success alert-dismissible fade show" role="alert">
						{success}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">Email Broadcast</h4>

							<form className="forms-sample" onSubmit={sendBroadCast}>
								<div className="form-group mb-3">
									<input
										className="form-control"
										onChange={(e) => {
											e.preventDefault();
											setSubject(e.target.value);
										}}
										placeholder="Enter your email subject"
									/>
								</div>
								<div className="form-group mb-3">
									<textarea
										className="form-control"
										cols="50"
										style={{
											height: "150px",
										}}
										onChange={(e) => {
											e.preventDefault();
											setSMSMessage(e.target.value);
										}}
										placeholder="Enter your message to send to all platform users. 100 Characters minimum"
									></textarea>
								</div>

								<span className="text-muted text-small">100 characters minimum</span>
								<br />
								<span className="text-muted text-small">HTML characters supported</span>
								<br />
								<span className="text-muted text-small">The character %s will be replaced with the user's username</span>
								<br />
								<br />

								<button type="submit" disabled={smsMessage.length >= 100 ? false : true} className="btn btn-primary mr-2">
									Send Email Broadcast
								</button>
							</form>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default SiteConfiguration;
