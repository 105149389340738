import React from "react";
import { Redirect } from "react-router-dom";
import * as appLinks from "../components/util/app_links";
import { useStoreState, useStoreRehydrated } from "easy-peasy";

const ProtectedRoute = ({ path, component }) => {
	let Component = component;

	const isRehydrated = useStoreRehydrated();
	const loggedIn = useStoreState((state) => state.loggedIn);

	return isRehydrated ? loggedIn ? <Component /> : <Redirect to={appLinks.default.signIn + "?return_to=" + path} /> : <div>Loading ...</div>;
};

const UnAuthenticatedOnly = ({ path, component }) => {
	let Component = component;

	const isRehydrated = useStoreRehydrated();
	const loggedIn = useStoreState((state) => state.loggedIn);

	return isRehydrated ? !loggedIn ? <Component /> : <Redirect to={`${appLinks.default.dashboard}?currency=btc`} /> : <div>Loading ...</div>;
};

export { ProtectedRoute, UnAuthenticatedOnly };
