/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import base64 from "base-64";
import Lightbox from "react-image-lightbox";
import DataTable from "react-data-table-component";
import FormLoading from "../../loader/form_loading";
import { validate_amount } from "../../util/misc";
import { Helmet } from "react-helmet";
import { useStoreState } from "easy-peasy";
import { isUuid } from "uuidv4";

import { SendPostRequest } from "../../util/request";

const UsersBody = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const userName = window.location.pathname.split("/")[2];

	const [photos, setPhotos] = useState(null);
	const [photoIndex, setPhotoIndex] = useState(0);

	const [jsonIDInfo, setJSONIDInfo] = useState(null);

	const [user, setUser] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.getUserInformationEndpoint, {
			token: account.token,
			username: userName,
		})
			.then(function (response) {
				setError(null);
				setLoaderShow(false);

				if (response.data.success) {
					setUser(response.data);

					if (response.data.kycs !== null && response.data.kycs.otherDocumentId.length > 1) {
						setJSONIDInfo(JSON.parse(base64.decode(response.data.kycs.otherDocumentId)));
					}
				} else {
					setError(response.data.message);
				}
			})
			.catch(() => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	/*const disableUser = (e, username) => {
		e.preventDefault();

		SendPostRequest(appLink.userAccountStatusEndpoint, {
			token: account.token,
			username: username,
			status: "disable",
		})
			.then(function (response) {
				if (response.data.success) {
					alert(`Account username (${username}) has been disabled.`);
					window.location.href = appLink.users;
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};*/

	const blockChainLink = (currency, transactionId) => {
		currency = currency.toLowerCase();

		return currency === "btc"
			? `https://www.blockchain.com/btc/tx/${transactionId}`
			: currency === "tron"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "usdt"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "trx"
			? `https://tronscan.io/#/transaction/${transactionId}`
			: currency === "bch"
			? `https://www.blockchain.com/bch/tx/${transactionId}`
			: currency === "ltc"
			? `https://chain.so/tx/LTC/${transactionId}`
			: currency === "eth"
			? `https://www.blockchain.com/eth/tx/${transactionId}`
			: currency === "bnb" || currency === "bsc"
			? `https://bscscan.com/tx/${transactionId}`
			: currency === "matic"
			? `https://polygonscan.com/tx/${transactionId}`
			: `https://chain.so/tx/DOGE/${transactionId}`;
	};

	const addressChainLink = (currency, address) => {
		currency = currency.toLowerCase();

		return currency === "btc"
			? `https://www.blockchain.com/btc/adddress/${address}`
			: currency === "tron"
			? `https://tronscan.io/#/address/${address}`
			: currency === "bch"
			? `https://www.blockchain.com/bch/address/${address}`
			: currency === "ltc"
			? `https://chain.so/address/LTC/${address}`
			: currency === "eth"
			? `https://www.blockchain.com/eth/adddress/${address}`
			: currency === "bnb" || currency === "bsc"
			? `https://bscscan.com/address/${address}`
			: currency === "matic"
			? `https://polygonscan.com/address/${address}`
			: `https://chain.so/address/DOGE/${address}`;
	};

	const mobileMoneyTopUps = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
			maxWidth: "45px",
		},
		{
			name: "Name",
			selector: "username",
			sortable: true,
			cell: (m) => (
				<span>
					{m.username} - {m.methodId}
				</span>
			),
		},
		{
			name: "TxID",
			sortable: true,
			selector: "uniqId",
			minWidth: "330px",
		},
		{
			name: "Local Currency",
			sortable: true,
			selector: "id",
			cell: (m) => (
				<span>
					{m.amount} {m.currency}
				</span>
			),
		},
		{
			name: "USD",
			sortable: true,
			selector: "usd",
			cell: (m) => <span>{validate_amount(m.usd, 2)}</span>,
		},
		{
			name: "Crypto",
			sortable: true,
			selector: "crypto",
			cell: (m) => (
				<span>
					{m.crypto} {m.cryptoSymbol}
				</span>
			),
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
			cell: (m) => <span>{m.status.toUpperCase()}</span>,
		},
		{
			name: "Date",
			sortable: true,
			selector: "date",
			cell: (m) => <span>{new Date(m.date).toDateString()}</span>,
		},
		{
			name: "Action",
			selector: "",
			cell: (m) => (
				<>
					<button className="btn btn-primary mr-1" onClick={(e) => viewMethodInfo(e, m.methodName, m.methodId)}>
						View
					</button>
					{m.status.toLowerCase() === "pending" && (
						<>
							<button className="btn btn-success mr-1" onClick={(e) => fundInfo(e, m.id, m.username)}>
								Fund
							</button>
						</>
					)}
				</>
			),
		},
	];

	const mobileMoneyWithdrawals = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
			maxWidth: "45px",
		},
		{
			name: "Name",
			selector: "username",
			sortable: true,
			cell: (m) => (
				<span>
					{m.username} - {m.paymenthodMethodId}
				</span>
			),
		},
		{
			name: "TxID",
			sortable: true,
			selector: "uniq",
			minWidth: "330px",
		},
		{
			name: "Local Currency",
			sortable: true,
			selector: "localCurrency",
			cell: (m) => (
				<span>
					{m.localCurrency} {m.receivableLocal}
				</span>
			),
		},
		{
			name: "USD",
			sortable: true,
			selector: "usdAmount",
			cell: (m) => <span>{m.usdAmount}</span>,
		},
		{
			name: "Crypto",
			sortable: true,
			selector: "crypto",
			cell: (m) => (
				<span>
					{m.cryptoAmount} {m.cryptoCurrency}
				</span>
			),
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
			cell: (m) => <span>{m.status.toUpperCase()}</span>,
		},
		{
			name: "Date",
			sortable: true,
			selector: "createdOn",
			cell: (m) => <span>{new Date(m.createdOn).toDateString()}</span>,
		},
		{
			name: "Action",
			selector: "",
			cell: (m) => (
				<>
					<button className="btn btn-primary mr-1" onClick={(e) => viewMethodInfo(e, m.paymentMethod, m.paymenthodMethodId)}>
						View Payment Info
					</button>
					{m.status.toLowerCase() === "pending" && (
						<>
							<button className="btn btn-success mr-1" onClick={(e) => updateWithdrawalStatus(e, m.username, m.id)}>
								Success
							</button>
							<button className="btn btn-danger mr-1" onClick={(e) => updateWithdrawalStatus(e, m.username, m.id, "failed")}>
								Cancel
							</button>
						</>
					)}
				</>
			),
		},
	];

	const mobileMoneyTransaction = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
			maxWidth: "45px",
		},
		{
			name: "Flow",
			selector: "type",
			sortable: true,
			maxWidth: "50px",
			cell: (m) => (
				<span>
					{m.type === "receive" ? "R <= " : m.type === "send" ? "S => " : "U => "} {!isUuid(m.txid) ? "OUT" : "IN"}
				</span>
			),
		},
		{
			name: "TxID",
			sortable: true,
			selector: "txid",
			minWidth: "550px",
			cell: (data) => (
				<React.Fragment>
					<div className="col-12">
						{isUuid(data.txid) ? (
							<span>{data.txid}</span>
						) : (
							<a href={blockChainLink(data.currency, data.txid)} target="_blank" rel="noreferrer noopener">
								{data.txid}
							</a>
						)}
						<br />
						{data.type === "send" && isUuid(data.txid) && (
							<>
								<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
									{data.username}
								</a>
								{" => "}
								<a href={appLink.user.replace(":id", data.recipient)} target="_blank" rel="noopener noreferrer">
									{data.recipient}
								</a>
							</>
						)}
						{data.type === "send" && !isUuid(data.txid) && (
							<>
								<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
									{data.username}
								</a>
								{" => "} <span>{data.recipient}</span>
							</>
						)}

						{data.type === "receive" && !isUuid(data.txid) && (
							<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
								{data.username}
							</a>
						)}
					</div>
				</React.Fragment>
			),
		},
		{
			name: "USD",
			sortable: true,
			selector: "usdAmount",
			cell: (m) => <span>$ {m.usdAmount}</span>,
		},
		{
			name: "Crypto",
			sortable: true,
			selector: "crypto",
			cell: (m) => (
				<span>
					{m.cryptoValue} {m.currency}
				</span>
			),
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
			cell: (data) => <>{data.status.toLowerCase() === "success" ? "SENT" : `${data.status.toUpperCase()} (${data.confirmations})`}</>,
		},
		{
			name: "Date",
			sortable: true,
			selector: "date",
			cell: (m) => <span>{new Date(m.date).toDateString()}</span>,
		},
	];

	const fundInfo = (e, id, username) => {
		e.preventDefault();

		SendPostRequest(appLink.fundUserAccountTopUpsEndpoint, {
			token: account.token,
			id: id,
			username: username,
		})
			.then(function (response) {
				if (response.data.success) {
					let message = "User has been funded and notified.";
					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	const updateBuyLimit = (username, limit) => {
		SendPostRequest(appLink.updateBuyLimit, {
			token: account.token,
			limit,
			username,
		})
			.then(function (response) {
				if (response.data.success) {
					let message = "Buying limit updated";
					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	const updataKYCStatus = (e, username, status, reason) => {
		e.preventDefault();

		SendPostRequest(appLink.updateUserEndpoint, {
			token: account.token,
			username: username,
			status: status,
			reason: reason,
		})
			.then(function (response) {
				if (response.data.success) {
					window.location.reload();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const viewMethodInfo = (e, methodName, methodId) => {
		e.preventDefault();

		SendPostRequest(appLink.viewPaymentMethodEndpoint, {
			token: account.token,
			methodName: methodName,
			methodId: methodId,
		})
			.then(function (response) {
				if (response.data.success) {
					let payInfo = response.data.data;
					let message =
						methodName === "momo"
							? `Account: ${base64.decode(payInfo.name)}\n\nCountry: ${payInfo.countryCode}\nNetwork: ${payInfo.networkCode}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: methodName === "bank"
							? `Account Name: ${payInfo.accountName.length !== 0 ? base64.decode(payInfo.accountName) : "None"}\nAccount Number: ${payInfo.accountNumber.length !== 0 ? base64.decode(payInfo.accountNumber) : "None"}\n\nCountry: ${
									payInfo.countryCode
							  }\n\nBank Name: ${base64.decode(payInfo.bankName)}\nSwift / BiC: ${payInfo.swiftBic.length !== 0 ? base64.decode(payInfo.swiftBic) : "None"}\nRouting Number: ${
									payInfo.routingNumber.length !== 0 ? base64.decode(payInfo.routingNumber) : "None"
							  }\n\nPayment Method Status: ${payInfo.active ? "Active" : "Disabled"}\nRegistered on ${payInfo.createdOn}`
							: methodName === "chipper"
							? `Account: ${base64.decode(payInfo.chipperName)}\n\nCountry: ${payInfo.countryCode}\nChipper Username: ${base64.decode(payInfo.Username)}\nPhone Number: ${payInfo.phoneNumber}\n\nPayment Method Status: ${
									payInfo.active ? "Active" : "Disabled"
							  }\nRegistered on ${payInfo.createdOn}`
							: "Unknown Payment Method";

					window.swal({
						text: message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
					//setPayInfo(response.data.data)
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	const updateWithdrawalStatus = (e, username, id, status = "success") => {
		e.preventDefault();

		SendPostRequest(appLink.updateWithdrawalStatus, {
			token: account.token,
			username: username,
			id: id,
			status: status,
		})
			.then(function (response) {
				if (response.data.success) {
					window.location.reload();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const deactivatePayment = (e, user, status, method) => {
		e.preventDefault();

		SendPostRequest(appLink.updateMethod, {
			token: account.token,
			id: user.id,
			active: status,
			username: user.username,
			method: method,
		})
			.then(function (response) {
				if (response.data.success) {
					window.swal({
						text: `Payment method for ${user.username} has been ${status ? "enabled" : "disabled"}. Reload page to see changes`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setError("Unable to connect to server. Check Internet");
			});
	};

	const userStats = (e, username, status) => {
		e.preventDefault();

		SendPostRequest(appLink.userAccountStatusEndpoint, {
			token: account.token,
			username: username,
			status: status,
		})
			.then(function (response) {
				if (response.data.success) {
					window.location.reload();
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const sendSMS = (username, message) => {
		SendPostRequest(appLink.sendSMSEndpoint, {
			token: account.token,
			username: username,
			message: message,
		})
			.then(function (response) {
				if (response.data.success) {
					window.swal({
						text: "SMS queued and sent successfully",
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const updateBalance = (username, crypto, cryptoSymbol, operation) => {
		SendPostRequest(appLink.fundBalanceEndpoint, {
			token: account.token,
			username: username,
			id: -1,
			operation,
			crypto: crypto,
			cryptoSymbol: cryptoSymbol,
		})
			.then(function (response) {
				if (response.data.success) {
					window.swal({
						text: response.data.message,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const TransactionHistory = ({ data }) => (
		<tr>
			<td>
				{data.type === "receive" ? "R <= " : data.type === "send" ? "S => " : "U => "} {!isUuid(data.txid) ? "OUT" : "IN"}
			</td>
			<td>
				{isUuid(data.txid) ? (
					data.txid
				) : (
					<a href={`https://www.blockchain.com/btc/tx/${data.txid}/`} target="_blank" rel="noreferrer noopener">
						{data.txid}
					</a>
				)}
				<br />
				{data.type === "send" && isUuid(data.txid) && (
					<>
						<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
							{data.username}
						</a>
						{" => "}
						<a href={appLink.user.replace(":id", data.recipient)} target="_blank" rel="noopener noreferrer">
							{data.recipient}
						</a>
					</>
				)}
				{data.type === "send" && !isUuid(data.txid) && (
					<>
						<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
							{data.username}
						</a>
						{" => "} {data.recipient}
					</>
				)}

				{data.type === "receive" && !isUuid(data.txid) && (
					<a href={appLink.user.replace(":id", data.username)} target="_blank" rel="noopener noreferrer">
						{data.username}
					</a>
				)}
			</td>
			<td>
				{data.cryptoValue} {data.currency} ( USD {data.usdAmount} )
			</td>
			<td>
				{`${data.status} (${data.confirmations})`} - {new Date(data.date).toDateString()}
			</td>
		</tr>
	);

	const viewPhotos = (e, data) => {
		e.preventDefault();
		setPhotos(data);
	};

	const closePhotos = () => {
		setPhotos(null);
		setPhotoIndex(0);
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>User {userName}</title>
			</Helmet>

			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						{photos !== null && (
							<Lightbox
								mainSrc={photos[photoIndex]}
								nextSrc={photos[(photoIndex + 1) % photos.length]}
								prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
								onCloseRequest={() => closePhotos()}
								onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
								onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
							/>
						)}
						<div className="card-body">
							<div className="row">
								<div className="col-12 table-responsive">
									<h1 className="mb-4">
										User Information ({userName}){" "}
										{user !== null &&
											(user.user.active ? (
												<>
													<button className="btn btn-outline-danger mr-1" onClick={(e) => userStats(e, userName, "disable")}>
														Disable Account
													</button>
													<button
														className="btn btn-outline-success mr-1"
														onClick={(e) => {
															e.preventDefault();

															let reason = prompt("Enter the SMS content. Limit for 1 SMS is 160 characters");

															if (reason === null || reason === undefined) {
																window.swal({
																	text: "SMS not sent because no message was provided",
																	button: {
																		text: "OK",
																		value: true,
																		visible: true,
																		className: "btn btn-primary",
																	},
																});
																return;
															}

															sendSMS(userName, reason);
														}}
													>
														Send SMS
													</button>
													<button
														className="btn btn-outline-warning ml-1"
														onClick={(e) => {
															e.preventDefault();

															let reason = prompt("Enter USD amount to update Buy Limit");

															if (reason === null || reason === undefined) {
																window.swal({
																	text: "Limit not updated because no amount was provided",
																	button: {
																		text: "OK",
																		value: true,
																		visible: true,
																		className: "btn btn-primary",
																	},
																});
																return;
															}

															updateBuyLimit(userName, reason);
														}}
													>
														Update Buy Limit
													</button>
												</>
											) : (
												<>
													<button className="btn btn-success mr-1" onClick={(e) => userStats(e, userName, "enable")}>
														Enable Account
													</button>
												</>
											))}
									</h1>

									{user !== null && (
										<React.Fragment>
											<table className="table w-100 mb-3">
												<tr>
													<td>Username: {user.user.username}</td>
													<td>First and Other Names: {user.user.firstName}</td>
													<td>Last Name: {user.user.lastName}</td>
													<td>
														Country: {user.user.countryCode} - {user.user.countryCurrency}
													</td>
												</tr>
												<tr>
													<td>Home address: {base64.decode(user.user.homeAddress, "base64")}</td>
													<td>
														GH Card No: {user.user.cardNo.length === 0 ? "No GhanaCard Submitted " : user.user.cardNo} ( Expires: {user.user.cardExpiry})
													</td>
													<td>State / Region: {base64.decode(user.user.stateRegion, "base64")}</td>
													<td>City / Town: {base64.decode(user.user.cityTown, "base64")}</td>
												</tr>
												<tr>
													<td>Phone number: {user.user.phone}</td>
													<td>Email: {user.user.email.length === 0 ? "No Email Available" : `${user.user.email} ( Verified: ${user.user.emailVerified} )`}</td>
													<td>KYC Verified: {user.user.kycVerified ? "YES" : "NO"}</td>
													<td>Sign Up Date: {new Date(user.user.createdOn).toDateString()}</td>
												</tr>
												<tr>
													<td>
														Buy Limit {user.user.buyAmountToday} ({user.user.buyLimitDate})
													</td>
													<td>
														Sell Limit {user.user.sellAmountToday} ({user.user.sellLimitDate})
													</td>
													<td>DOB: {user.user.dob}</td>
													<td>Gender: {user.user.gender}</td>
												</tr>
												<tr>
													<td>Can Send: {user.user.canSend.toString().toUpperCase()}</td>
													<td>Can Deposit: {user.user.canDeposit.toString().toUpperCase()}</td>
													<td>Can Withdraw: {user.user.canWithdraw.toString().toUpperCase()}</td>
													<td>Can Swap: {user.user.canWithdraw.toString().toUpperCase()}</td>
												</tr>
												<tr>
													<td>Can Trade: {user.user.canTrade.toString().toUpperCase()}</td>
													<td>Verification TIER: {user.user.verificationTier}</td>
													<td>MFA: {user.user.mfaEnabled.toString().toUpperCase()}</td>
													<td>USER ID: {user.user.id}</td>
												</tr>
											</table>
											<h4>Payment Accounts</h4>
											<table className="table w-100 mb-3">
												{user.momo.length > 0 ? (
													<React.Fragment>
														{user.momo.map((l) => (
															<tr>
																<td>
																	MOBILE MONEY: {base64.decode(l.name, "base64")} ({l.phoneNumber}) - {l.id}
																</td>
																<td>
																	Network: {l.networkCode} - {l.countryCode}
																</td>
																<td>Registered On: {new Date(l.createdOn).toDateString()}</td>
															</tr>
														))}

														{user.bank.map((l) => (
															<tr>
																<td>
																	BANK ({l.countryCode} - {l.bankName})
																</td>
																<td>
																	{base64.decode(l.accountName)} ({base64.decode(l.accountNumber)})
																</td>
																<td>
																	{l.active ? (
																		<button className="btn btn-danger mr-1" onClick={(e) => deactivatePayment(e, l, false, "bank")}>
																			Deactivate
																		</button>
																	) : (
																		<button className="btn btn-success mr-1" onClick={(e) => deactivatePayment(e, l, true, "bank")}>
																			Activate
																		</button>
																	)}
																</td>
															</tr>
														))}
													</React.Fragment>
												) : (
													<span className="text-info">User does not a mobile money payment accounts registered yet</span>
												)}
											</table>
											<h4>Account Balance</h4>
											<table className="table w-100 mb-3">
												{user.balances.map((l) => (
													<tr>
														<td>
															Cryptocurrency: {l.crypto}
															<br />
															{user.addresses
																.filter((address) => address.currency.toUpperCase() === l.crypto.toUpperCase())
																.map((addr) => (
																	<span>
																		Address:{" "}
																		<a href={addressChainLink(l.crypto, addr.address)} target="_blank" rel="noreferrer noopener">
																			{addr.address}
																		</a>{" "}
																		({addr.addressType.toUpperCase()})
																	</span>
																))}
														</td>
														<td>
															Balance: {validate_amount(l.balance)} {l.crypto}
														</td>
														<td>USD {validate_amount(parseFloat(l.balance) * parseFloat(user.prices.filter((coin) => coin.coin.toLowerCase() === l.crypto.toLowerCase())[0].value), 2)}</td>

														<td>
															<button
																className="btn btn-danger mr-1"
																onClick={(e) => {
																	e.preventDefault();

																	let amount = prompt(`Enter ${l.crypto} amount to deduct from ${userName}'s account`);

																	if (amount === null || amount === undefined) {
																		window.swal({
																			text: `${userName} ${l.crypto} balance not deducted`,
																			button: {
																				text: "OK",
																				value: true,
																				visible: true,
																				className: "btn btn-primary",
																			},
																		});
																		return;
																	}

																	updateBalance(userName, amount, l.crypto.toUpperCase(), "-");
																}}
															>
																Deduct
															</button>
															<button
																className="btn btn-success ml-1"
																onClick={(e) => {
																	e.preventDefault();

																	let amount = prompt(`Enter ${l.crypto} amount to add to ${userName}'s account`);

																	if (amount === null || amount === undefined) {
																		window.swal({
																			text: `${userName} ${l.crypto} balance not topped up`,
																			button: {
																				text: "OK",
																				value: true,
																				visible: true,
																				className: "btn btn-primary",
																			},
																		});
																		return;
																	}

																	updateBalance(userName, amount, l.crypto.toUpperCase(), "+");
																}}
															>
																Add
															</button>
														</td>
													</tr>
												))}

												{user.miscBalances !== null &&
													user.miscBalances.map((miscBal) => (
														<React.Fragment>
															<tr>
																<td>
																	Cryptocurrency: {miscBal.name}
																	<br />
																	Address:
																	<a href={addressChainLink(miscBal.symbol, miscBal.address)} target="_blank" rel="noreferrer noopener">
																		{miscBal.address}
																	</a>{" "}
																	( {miscBal.platform} - {miscBal.symbol} )
																</td>
																<td>
																	Balance: {miscBal.balance} {miscBal.symbol}
																</td>
																<td></td>
																<td></td>
															</tr>
														</React.Fragment>
													))}
											</table>

											<h4>KYC Verification</h4>
											<table className="table w-100 mb-3">
												{user.user.kycVerified ? (
													<>
														<span className="text-success">User kyc documents have been approved</span>
														<br />
														<tr>
															<td>{user.kycs.username}</td>
															<td>
																<a
																	onClick={(e) =>
																		user.kycs.photoId.length > 5
																			? viewPhotos(e, [base64.decode(user.kycs.photoId), base64.decode(user.kycs.SelfieId), base64.decode(user.kycs.CardId)])
																			: viewPhotos(e, [base64.decode(user.kycs.SelfieId), base64.decode(user.kycs.CardId)])
																	}
																	className="btn btn-primary mr-1"
																	rel="noreferrer noopener"
																	href={"#/"}
																	target="_blank"
																>
																	View Photos
																</a>
															</td>
															<td>
																<button
																	className="btn btn-outline-danger"
																	onClick={(e) => {
																		let reason = prompt("Reason for disapproving user kyc. This is shown to the user to help them do better when re-uploading their kyc");

																		if (reason === null || reason === undefined) {
																			window.swal({
																				text: "You need to provide a reason for suspending a valid and approved KYC document",
																				button: {
																					text: "OK",
																					value: true,
																					visible: true,
																					className: "btn btn-primary",
																				},
																			});
																			return;
																		}

																		updataKYCStatus(e, user.kycs.username, "error", reason);
																	}}
																>
																	Reject
																</button>
															</td>
															<td>{new Date(user.kycs.createdOn).toDateString()}</td>
														</tr>
													</>
												) : user.kycs !== null ? (
													<tr>
														<td>{user.kycs.username}</td>
														<td>
															<a
																onClick={(e) =>
																	user.kycs.photoId.length > 5
																		? viewPhotos(e, [base64.decode(user.kycs.photoId), base64.decode(user.kycs.SelfieId), base64.decode(user.kycs.CardId)])
																		: viewPhotos(e, [base64.decode(user.kycs.SelfieId), base64.decode(user.kycs.CardId)])
																}
																className="btn btn-primary mr-1"
																rel="noreferrer noopener"
																href={"#/"}
																target="_blank"
															>
																View Photos
															</a>
														</td>
														<td>
															<button
																className="btn btn-success mr-2"
																onClick={(e) => {
																	updataKYCStatus(e, user.kycs.username, "success", "");
																}}
															>
																Approve
															</button>

															<button
																className="btn btn-outline-danger"
																onClick={(e) => {
																	let reason = prompt("Reason for disapproving user kyc. This is shown to the user to help them do better when re-uploading their kyc");

																	if (reason === null || reason === undefined) {
																		window.swal({
																			text: "You need to provide a reason for suspending a valid and approved KYC document",
																			button: {
																				text: "OK",
																				value: true,
																				visible: true,
																				className: "btn btn-primary",
																			},
																		});
																		return;
																	}

																	updataKYCStatus(e, user.kycs.username, "error", reason);
																}}
															>
																Reject
															</button>
														</td>
														<td>{new Date(user.kycs.createdOn).toDateString()}</td>
													</tr>
												) : (
													<span className="text-info">User has not submitted any documents yet</span>
												)}
											</table>

											{user.user.kycVerified ? (
												<React.Fragment>
													{user.kycs !== null && user.kycs.otherDocumentId.length > 5 && jsonIDInfo !== null && (
														<div className="card card-body">
															<div className="col-12">
																<div className="row">
																	<div className="col-3">
																		{!jsonIDInfo.picture ? (
																			<img src={base64.decode(user.kycs.photoId)} width="150" height="150" alt="User" />
																		) : (
																			<img src={`data:image/png;base64,${jsonIDInfo.picture}`} width="150" height="150" alt="User" />
																		)}
																	</div>

																	<div className="col-9">
																		<div className="row">
																			<div className="col">
																				<div className="row">
																					{jsonIDInfo.id && <div className="w-100">ID: {jsonIDInfo.id}</div>}
																					{jsonIDInfo.first_name && <div className="w-100">First Name: {jsonIDInfo.first_name}</div>}
																					{jsonIDInfo.full_name && <div className="w-100">Full Name: {jsonIDInfo.full_name}</div>}
																					{jsonIDInfo.certificate_date && <div className="w-100">Certificate Date: {jsonIDInfo.certificate_date}</div>}
																					{jsonIDInfo.processing_center && <div className="w-100">Processing Center: {jsonIDInfo.processing_center}</div>}
																					{jsonIDInfo.pin && <div className="w-100">PIN: {jsonIDInfo.pin}</div>}

																					{jsonIDInfo.last_name && <div className="w-100">Last Name: {jsonIDInfo.last_name}</div>}
																					{jsonIDInfo.middle_name && <div className="w-100">Other Name: {jsonIDInfo.middle_name}</div>}
																					{jsonIDInfo.date_of_birth && <div className="w-100">DOB: {jsonIDInfo.date_of_birth}</div>}
																					{jsonIDInfo.gender && <div className="w-100">Gender: {jsonIDInfo.gender}</div>}

																					{jsonIDInfo.polling_station && <div className="w-100">Polling Station: {jsonIDInfo.polling_station}</div>}
																					{jsonIDInfo.registration_date && <div className="w-100">Registration: {jsonIDInfo.registration_date}</div>}
																				</div>
																			</div>

																			<div className="col">
																				<div className="row">
																					{jsonIDInfo.issue_date && <div className="w-100">Issue Date: {jsonIDInfo.issue_date}</div>}
																					{jsonIDInfo.expiry_date && <div className="w-100">Expiry Date: {jsonIDInfo.expiry_date}</div>}

																					{jsonIDInfo.place_of_birth && <div className="w-100">Place of Birth: {jsonIDInfo.place_of_birth}</div>}
																					{jsonIDInfo.place_of_issue && <div className="w-100">Place of Issue: {jsonIDInfo.place_of_issue}</div>}
																					{jsonIDInfo.documentType && <div className="w-100">Document Type: {jsonIDInfo.documentType}</div>}

																					{jsonIDInfo.dob && <div className="w-100">DOB: {new Date(jsonIDInfo.dob).toDateString()}</div>}
																					{jsonIDInfo.cardNo && <div className="w-100">Card No: {jsonIDInfo.cardNo}</div>}
																					{jsonIDInfo.homeAddress && <div className="w-100">GhanaPostGPS: {jsonIDInfo.homeAddress}</div>}
																					{jsonIDInfo.cardExpiry && <div className="w-100">Card Expiry: {new Date(jsonIDInfo.cardExpiry).toDateString()}</div>}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)}
												</React.Fragment>
											) : (
												<React.Fragment>
													{user.kycs !== null && user.kycs.otherDocumentId.length > 5 && jsonIDInfo !== null && (
														<div className="card card-body">
															<div className="col-12">
																<div className="row">
																	<div className="col-3">
																		<img src={`data:image/png;base64,${jsonIDInfo.picture}`} width="150" height="150" alt="User" />
																	</div>

																	<div className="col-9">
																		<div className="row">
																			<div className="col">
																				<div className="row">
																					{jsonIDInfo.id && (
																						<div className="w-100">
																							ID: {jsonIDInfo.id} ( {jsonIDInfo.documentType ?? jsonIDInfo.first_name ? "Passport" : jsonIDInfo.polling_station ? "Voters ID" : "Driver License"} )
																						</div>
																					)}
																					{jsonIDInfo.first_name && <div className="w-100">First Name: {jsonIDInfo.first_name}</div>}
																					{jsonIDInfo.full_name && <div className="w-100">Full Name: {jsonIDInfo.full_name}</div>}
																					{jsonIDInfo.certificate_date && <div className="w-100">Certificate Date: {jsonIDInfo.certificate_date}</div>}
																					{jsonIDInfo.processing_center && <div className="w-100">Processing Center: {jsonIDInfo.processing_center}</div>}
																					{jsonIDInfo.pin && <div className="w-100">PIN: {jsonIDInfo.pin}</div>}

																					{jsonIDInfo.last_name && <div className="w-100">Last Name: {jsonIDInfo.last_name}</div>}
																					{jsonIDInfo.middle_name && <div className="w-100">Other Name: {jsonIDInfo.middle_name}</div>}
																					{jsonIDInfo.date_of_birth && <div className="w-100">DOB: {jsonIDInfo.date_of_birth}</div>}
																					{jsonIDInfo.gender && <div className="w-100">Gender: {jsonIDInfo.gender}</div>}

																					{jsonIDInfo.polling_station && <div className="w-100">Polling Station: {jsonIDInfo.polling_station}</div>}
																					{jsonIDInfo.registration_date && <div className="w-100">Registration: {jsonIDInfo.registration_date}</div>}
																				</div>
																			</div>

																			<div className="col">
																				<div className="row">
																					{jsonIDInfo.issue_date && <div className="w-100">Issue Date: {jsonIDInfo.issue_date}</div>}
																					{jsonIDInfo.expiry_date && <div className="w-100">Expiry Date: {jsonIDInfo.expiry_date}</div>}

																					{jsonIDInfo.place_of_birth && <div className="w-100">Place of Birth: {jsonIDInfo.place_of_birth}</div>}
																					{jsonIDInfo.place_of_issue && <div className="w-100">Place of Issue: {jsonIDInfo.place_of_issue}</div>}
																					{jsonIDInfo.documentType && <div className="w-100">Document Type: {jsonIDInfo.documentType}</div>}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)}
												</React.Fragment>
											)}

											<div id="exTab2" className="container col-12">
												<ul className="nav nav-tabs">
													<li className="active pr-3">
														<a href="#1" data-toggle="tab">
															Mobile Money Top Ups
														</a>
													</li>
													<li>
														<a href="#2" data-toggle="tab" className="pr-3">
															Mobile Money Withdrawals
														</a>
													</li>
													<li>
														<a href="#3" data-toggle="tab" className="pr-3">
															Transactions
														</a>
													</li>
												</ul>

												<div className="tab-content ">
													<div className="tab-pane active" id="1">
														<DataTable
															title={"Mobile Money Top Ups"}
															columns={mobileMoneyTopUps}
															defaultSortField={"id"}
															pagination
															noDataComponent={"There is no data available"}
															defaultSortAsc={false}
															highlightOnHover
															pointerOnHover
															paginationPerPage={10}
															data={user.topUps}
														/>
													</div>
													<div className="tab-pane" id="2">
														<DataTable
															title={"Mobile Money Withdrawals"}
															columns={mobileMoneyWithdrawals}
															defaultSortField={"id"}
															pagination
															noDataComponent={"There is no data available"}
															defaultSortAsc={false}
															highlightOnHover
															pointerOnHover
															paginationPerPage={10}
															data={user.withdrawals}
														/>
													</div>
													<div className="tab-pane" id="3">
														<DataTable
															title={"Transactions"}
															columns={mobileMoneyTransaction}
															defaultSortField={"id"}
															pagination
															noDataComponent={"There is no data available"}
															defaultSortAsc={false}
															highlightOnHover
															pointerOnHover
															paginationPerPage={10}
															data={user.transactions}
														/>
													</div>
												</div>
											</div>

											<hr></hr>
										</React.Fragment>
									)}
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default UsersBody;
