import axios from "axios";
axios.defaults.withCredentials = true;

const validateStatus = (status) => status === 200 || status === 201;
const requestHeaders = {
	"Content-Type": "application/json",
};
const SendPostRequest = (url, data) => {
	return axios.post(url, data, {
		headers: requestHeaders,
		validateStatus: validateStatus,
	});
};

const SendGetRequest = (url, data) => {
	return axios.get(url, {
		headers: requestHeaders,
		validateStatus: validateStatus,
	});
};

const SendPutRequest = (url, data) => {
	return axios.put(url, data, {
		headers: requestHeaders,
		validateStatus: validateStatus,
	});
};
export { SendPostRequest, SendGetRequest, SendPutRequest };
