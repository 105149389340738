/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import base64 from "base-64";
import FormLoading from "../../loader/form_loading";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";

const UsersBody = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [users, setUsers] = useState(null);

	useEffect(() => {
		setLoaderShow(true);

		SendPostRequest(appLink.disabledUsersEndpoint, {
			token: account.token,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setUsers(response.data.users);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	}, []);

	const enableUser = (e, username) => {
		e.preventDefault();

		SendPostRequest(appLink.userAccountStatusEndpoint, {
			token: account.token,
			username: username,
			status: "enable",
		})
			.then(function (response) {
				if (response.data.success) {
					const activeUsers = users.filter((user) => user.username !== username);
					setUsers(activeUsers);

					window.swal({
						text: `The account with username (${username}) has been enabled.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">The list of disabled users registered with the platform</h4>
							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table">
										<thead>
											<tr>
												<th>Username</th>
												<th>Email</th>
												<th>Phone</th>
												<th>Country</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{users !== null &&
												users.map((user, index) => (
													<tr key={index}>
														<td>{user.username}</td>
														<td>{user.email}</td>
														<td>{user.phone}</td>
														<td>{user.countryCode}</td>
														<td>
															<a className="btn btn-primary mr-1" href={appLink.user.replace(":id", user.username)} target="_blank" rel="noopener noreferrer">
																View
															</a>

															<button className="btn btn-outline-success" onClick={(e) => enableUser(e, user.username)}>
																Enable
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default UsersBody;
