import React from "react";
import Wrapper from "../misc/wrapper";
import DashboardView from "./body";
import { Helmet } from "react-helmet";
import AppLinks from "../../util/app_links";

const PlatformDashboard = () => {
	return (
		<React.Fragment>
			<Helmet>
				<title>Dashboard | BitAfrika</title>
			</Helmet>

			<Wrapper appLink={AppLinks}>
				<DashboardView appLink={AppLinks} />
			</Wrapper>
		</React.Fragment>
	);
};

export default PlatformDashboard;
