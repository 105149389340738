/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import FormLoading from '../../loader/form_loading';
import { useStoreState } from 'easy-peasy';
import { SendPostRequest } from '../../util/request';

const AirtimeList = ({ appLink }) => {

    const [error, setError] = useState(null);
    const [loaderShow, setLoaderShow] = useState(false);

    const account = useStoreState((state) => state.account);

    const [topUps, setData] = useState(null);

    useEffect(() => {

        setLoaderShow(true);

        SendPostRequest(
            appLink.airtimeEndpoint,
            {
                token: account.token
            }
        ).then(function (response) {

            setLoaderShow(false);

            if (response.data.success) {
                setData(response.data.data);
            } else {
                setError(response.data.message);
            }

        }).catch((error) => {

            setLoaderShow(false);

            setError("Unable to connect to server. Check Internet")

        });

    }, []);

    return (
        <React.Fragment>
            <div className="card">

                {error !== null && (
                    <div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
                        {error}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                )}

                {
                    !loaderShow ? (
                        <React.Fragment>

                            <div className="card-body">
                                <h4 className="card-title">The list of airtime top ups on the platform</h4>
                                <div className="row">
                                    <div className="col-12 table-responsive">
                                        <table id="order-listing" className="table">
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Amount (L)</th>
                                                    <th>Amount ($)</th>
                                                    <th>Amount (C)</th>
                                                    <th>Phone</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {topUps !== null && topUps.map((data, index) =>

                                                    <tr key={index}>
                                                        <td>{data.username}</td>
                                                        <td>{data.amount} {data.currency}</td>
                                                        <td>${data.usd}</td>
                                                        <td>{data.crypto} {data.cryptoSymbol}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{data.date}</td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                            <FormLoading />
                        )
                }
            </div>

        </React.Fragment>
    )
}

export default AirtimeList;